import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';

import { AppComponent } from "./app.component";
import { RouterModule } from "@angular/router";
import { LayoutModule } from "./layout/layout.module";
import { AppRoutingModule } from "./app-routing.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { JwPaginationModule } from "jw-angular-pagination";

import { AuthGuard } from "./auth.guard";

import { HomeModule } from "./home/home.module";

import { LoginModule } from "./login/login.module";

import { RegisterModule } from "./register/register.module";

import { ChangePasswordModule } from "./change-password/change-password.module";

import { LogoutModule } from "./logout/logout.module";

import { DashboardModule } from "./dashboard/dashboard.module";

import { GalleryModule } from "./gallery/gallery.module";

import { ProfileModule } from "./profile/profile.module";

import { ProfileNewModule } from "./profile-new/profile-new.module";

import { ThankyouModule } from "./thankyou/thankyou.module";

import { DeleteAccountModule } from "./delete-account/delete-account.module";

import { DeleteAccountThankyouModule } from "./delete-account-thankyou/delete-account-thankyou.module";

import { PrivacyPolicyModule } from "./privacy-policy/privacy-policy.module";

import { TermsConditionModule } from "./terms-condition/terms-condition.module";

import { BusinessListingModule } from "./business-listing/business-listing.module";

import { PublicProfileModule } from "./public-profile/public-profile.module";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    JwPaginationModule,
    HttpClientModule,
    RouterModule.forRoot([]),
    AppRoutingModule,
    FlexLayoutModule,
    LayoutModule,
    HomeModule,
    LoginModule,
    RegisterModule,
    ChangePasswordModule,
    LogoutModule,
    DashboardModule,
    GalleryModule,
    ProfileModule,
    ProfileNewModule,
    GooglePlaceModule,
    DeleteAccountModule,
    DeleteAccountThankyouModule,
    ThankyouModule,
    PrivacyPolicyModule,
    PublicProfileModule,
    TermsConditionModule,
    BusinessListingModule
  ],
  providers: [AuthGuard],
  bootstrap: [AppComponent],
})
export class AppModule {}
