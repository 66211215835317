import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { BusinessMaster } from "../models/businessMaster";

import { environment } from '../../environments/environment';

@Injectable({
  	providedIn: "root",
})
export class BusinessMasterService {
	
	private addBusinessMasterUrl = environment.baseURL + "businessMaster/addBusinessMaster";

	private getBusniessMasterByUserIdUrl = environment.baseURL + "businessMaster/getBusniessMasterByUserId";

	private getGalleryImagesByUserIdUrl = environment.baseURL + "businessMaster/getGalleryImagesByUserId";

	private getAllBusinessByUserIdUrl = environment.baseURL + "businessMaster/getAllBusinessMasterByUserId";

	private getBusinessByIdUrl = environment.baseURL + "businessMaster/getBusinessById";

	private getGalleryImagesByIdUrl = environment.baseURL + "businessMaster/getGalleryImagesById";

	private getInfoByBusinessIdUrl = environment.baseURL + "businessMaster/getInfoByBusinessId";

	private updateBioUrl = environment.baseURL + "businessMaster/updateBio";

	private updateBusinessInfoUrl = environment.baseURL + "businessMaster/updateBusinessInfo";

	private updateMakePublicUrl = environment.baseURL + "businessMaster/updateMakePublic";
	
	private updateNewsletterUrl = environment.baseURL + "businessMaster/updateNewsletter";
	
	private updateLatestUpdateUrl = environment.baseURL + "businessMaster/updateLatestUpdate";

	private addChildBusinessMasterUrl = environment.baseURL + "businessMaster/addChildBusinessMaster";

	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

  	constructor(private http: HttpClient) {}

	addBusinessMaster(businessMaster: BusinessMaster): Observable<BusinessMaster> {
		return this.http
		.post<BusinessMaster>(this.addBusinessMasterUrl, businessMaster, this.httpOptions)
		.pipe(
			map((businessMaster) => {
			return businessMaster;
			})
		);
	}
	
	getBusniessMasterByUserId(businessMaster: BusinessMaster): Observable<BusinessMaster> {
		return this.http
		.post<BusinessMaster>(this.getBusniessMasterByUserIdUrl, businessMaster, this.httpOptions)
		.pipe(
			map((businessMaster) => {
			return businessMaster;
			})
		);
	}

	getGalleryImagesByUserId(businessMaster: BusinessMaster): Observable<BusinessMaster> {
		return this.http
		.post<BusinessMaster>(this.getGalleryImagesByUserIdUrl, businessMaster, this.httpOptions)
		.pipe(
			map((businessMaster) => {
			return businessMaster;
			})
		);
	}

	getAllBusinessByUserId(user_id: BusinessMaster): Observable<BusinessMaster> {
		return this.http
		.post<BusinessMaster>(this.getAllBusinessByUserIdUrl, user_id, this.httpOptions)
		.pipe(
			map((businessMaster) => {
			return businessMaster;
			})
		);
	}
	
	getBusinessById(b_id: BusinessMaster): Observable<BusinessMaster> {
		return this.http
		.post<BusinessMaster>(this.getBusinessByIdUrl, b_id, this.httpOptions)
		.pipe(
			map((businessMaster) => {
			return businessMaster;
			})
		);
	}

	getGalleryImagesById(b_id: BusinessMaster): Observable<BusinessMaster> {
		return this.http
		.post<BusinessMaster>(this.getGalleryImagesByIdUrl, b_id, this.httpOptions)
		.pipe(
			map((businessMaster) => {
			return businessMaster;
			})
		);
	}

	getInfoByBusinessId(businessMaster: BusinessMaster): Observable<BusinessMaster> {
		return this.http
		.post<BusinessMaster>(this.getInfoByBusinessIdUrl, businessMaster, this.httpOptions)
		.pipe(
			map((businessMaster) => {
			return businessMaster;
			})
		);
	}

	updateBio(businessMaster: BusinessMaster): Observable<BusinessMaster> {
		return this.http
		.post<BusinessMaster>(this.updateBioUrl, businessMaster, this.httpOptions)
		.pipe(
			map((businessMaster) => {
			return businessMaster;
			})
		);
	}

	updateBusinessInfo(businessMaster: BusinessMaster): Observable<BusinessMaster> {
		return this.http
		.post<BusinessMaster>(this.updateBusinessInfoUrl, businessMaster, this.httpOptions)
		.pipe(
			map((businessMaster) => {
			return businessMaster;
			})
		);
	}
	
    updateMakePublic(businessMaster: BusinessMaster): Observable<BusinessMaster> {
		return this.http
		.post<BusinessMaster>(this.updateMakePublicUrl, businessMaster, this.httpOptions)
		.pipe(
			map((businessMaster) => {
			return businessMaster;
			})
		);
	}

	updateNewsletter(businessMaster: BusinessMaster): Observable<BusinessMaster> {
		return this.http
		.post<BusinessMaster>(this.updateNewsletterUrl, businessMaster, this.httpOptions)
		.pipe(
			map((businessMaster) => {
			return businessMaster;
			})
		);
	}

	updateLatestUpdate(businessMaster: BusinessMaster): Observable<BusinessMaster> {
		return this.http
		.post<BusinessMaster>(this.updateLatestUpdateUrl, businessMaster, this.httpOptions)
		.pipe(
			map((businessMaster) => {
			return businessMaster;
			})
		);
	}

	addChildBusinessMaster(businessMaster: BusinessMaster): Observable<BusinessMaster> {
		return this.http
		.post<BusinessMaster>(this.addChildBusinessMasterUrl, businessMaster, this.httpOptions)
		.pipe(
			map((businessMaster) => {
			return businessMaster;
			})
		);
	}

}
