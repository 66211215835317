import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { User } from '../models/user';
import { AuthenticationService } from '../services/authentication.service';

@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: [
		'./register.component.css',
		'../../assets/css/material-dashboard.css?v=3.1.0',
		'../../assets/css/responsive.css',
	]
})

export class RegisterComponent implements OnInit {

	registerForm = new FormGroup({
		first_name: new FormControl('', [Validators.required]),
		last_name: new FormControl('', [Validators.required]),
		phone: new FormControl('', [Validators.required]),
	});

	otpForm = new FormGroup({
		verifyPhone: new FormControl('', [Validators.required]),
		otp1: new FormControl('', [Validators.required]),
		otp2: new FormControl('', [Validators.required]),
		otp3: new FormControl('', [Validators.required]),
		otp4: new FormControl('', [Validators.required])
	});

	get f() {
		return this.registerForm.controls;
	}
	
	get g() {
		return this.otpForm.controls;
	}

	users: User[] = [];
	otpSent = false;
	errorMessage = '';
	requestPhone = '';
	successMessage = '';
	formErrorMessages = {
		firstNameError: '',
		lastNameError: '',
		phoneError: '',
		verifyPhoneError: '',
		otpError: ''
	};

	constructor(
		private authenticationService: AuthenticationService,
		private router: Router,
	) { }

	ngOnInit(): void {
		var currentUser = localStorage.getItem('currentUser');
		if(currentUser){
			this.router.navigate(['dashboard']);
		}
	}

	register($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;
		let phone = this.registerForm.value.phone.trim();
		let first_name = this.registerForm.value.first_name.trim();
		let last_name = this.registerForm.value.last_name.trim();
		if (!phone || !first_name || !last_name) {
			const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
			let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
			dynamicMessage.value = 'All fields are required';
			errorMessage?.click();
			($event.submitter as HTMLButtonElement).disabled = false;
			return;
		}
		this.authenticationService.register({ 
			first_name,
			last_name,
			phone 
			} as User)
			.subscribe(
				data => {
					this.errorMessage = '';
					if (data.status == 200) {
						this.successMessage = data.message;
						this.otpSent = true;
						this.otpForm.controls["verifyPhone"].setValue(data.data.phone);
						this.requestPhone = data.data.phone;
					} else {
						const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
						let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
						dynamicMessage.value = data.message;
						errorMessage?.click();
						($event.submitter as HTMLButtonElement).disabled = false;
					}
				},
				error => {
					const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
					let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
					dynamicMessage.value = error;
					errorMessage?.click();
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			);
	}

	verifyOtp($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;

		let phone = this.otpForm.value.verifyPhone.trim();
		let otp1 = this.otpForm.value.otp1;
		let otp2 = this.otpForm.value.otp2;
		let otp3 = this.otpForm.value.otp3;
		let otp4 = this.otpForm.value.otp4;
		let otp = otp1 + '' + otp2 + '' + otp3 + '' + otp4;

		if (!phone || !otp) {
			const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
			let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
			dynamicMessage.value = 'All fields are required';
			errorMessage?.click();
			($event.submitter as HTMLButtonElement).disabled = false;
			return;
		}
		this.authenticationService.verifyOtp({ phone, otp } as User)
			.subscribe(
				data => {
					this.errorMessage = '';
					if (data.status == 200) {
						localStorage.setItem('currentUser', JSON.stringify(data));
						setTimeout(() => {
							this.router.navigate(['profile']);
						}, 2000);
					} else {
						const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
						let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
						dynamicMessage.value = data.message;
						errorMessage?.click();
						($event.submitter as HTMLButtonElement).disabled = false;
					}
				},
				error => {
					const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
					let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
					dynamicMessage.value = error;
					errorMessage?.click();
					($event.submitter as HTMLButtonElement).disabled = false;
					}
			);
	}
}
