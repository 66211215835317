import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: [
		'./home.component.css',
		'../../assets/home_assets/css/bootstrap.min.css',
		'../../assets/home_assets/css/animate.css',
		'../../assets/home_assets/css/all.css',
		'../../assets/home_assets/css/slick.css',
		'../../assets/home_assets/css/fancybox.css',
		'../../assets/home_assets/css/style.css'
	]
})
export class HomeComponent implements OnInit {

	constructor(
	) { }

	ngOnInit(): void {
	}

}