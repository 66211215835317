<style>
    @media (min-width: 992px){
        .symbol.symbol-lg-160px.symbol-fixed > img {
            width: auto;
            height: auto;
        }
    }
	@media (min-width: 992px){
		.header {
			height: 50px;
		}
	}
	.aside-close{
		left: 8% !important;
		max-width: 95%!important;
	}
	@media (min-width: 992px){
		.aside .aside-primary {
			width: 40px;
		}	
		.wrapper {
			left: 19%;
			max-width: 80%;
		}
	}
	.navbar {
		background: #00446d;
		justify-content: start;
	}
 	.navbar img{ width: 4%;}
 	.navbar h2{
 		font-size: 14px;
		color: #fff;
		margin-left: 20px;
	}
	.small-sidebar .menu-title {
		display: none !important;
	}
	.small-sidebar-multi {
		display: none !important;
	}
	.small-sidebar .small-sidebar-icon {
		font-size: 30px !important;
	}
</style>
<div class="d-flex flex-column flex-root">
	<div class="navbar" style="padding: 10px 100px;">
		<img src="assets/new_assets/media/logos/logo.png">
		<h2>Nirmanalekh - Tribal Welfare Department, Madhya Pradesh</h2>
	</div>
	<div class="page d-flex flex-row flex-column-fluid">
		<div id="kt_aside" class="aside aside-extended" data-kt-drawer="true" data-kt-drawer-name="aside" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="auto" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_aside_mobile_toggle">
			<div class="aside-secondary d-flex flex-row-fluid">
				<div class="aside-workspace my-5 p-5" id="kt_aside_wordspace">
					<div class="d-flex h-100 flex-column">
						<div class="flex-column-fluid hover-scroll-y" data-kt-scroll="true" data-kt-scroll-activate="true" data-kt-scroll-height="auto" data-kt-scroll-wrappers="#kt_aside_wordspace" data-kt-scroll-dependencies="#kt_aside_secondary_footer" data-kt-scroll-offset="0px">
							<div class="tab-content">
								<div class="tab-pane fade active show" id="kt_aside_nav_tab_menu" role="tabpanel">
									<div class="menu menu-column menu-fit menu-rounded menu-title-gray-600 menu-icon-gray-400 menu-state-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-semibold fs-5 px-6 my-5 my-lg-0" id="kt_aside_menu" data-kt-menu="true">
										<div id="kt_aside_menu_wrapper" class="menu-fit">
											<div class="menu-item" [ngClass]="smallSidebar ? 'small-sidebar' : ''">
												<a class="menu-link" href="dashboard" title="Home">
													<span class="menu-icon">
														<i class="ki-duotone ki-element-11 fs-2 small-sidebar-icon">
															<span class="path1"></span>
															<span class="path2"></span>
															<span class="path3"></span>
															<span class="path4"></span>
														</i>
													</span>
													<span class="menu-title">Home</span>
												</a>
											</div>
											<div data-kt-menu-trigger="click" class="menu-item show menu-accordion" [ngClass]="smallSidebar ? 'small-sidebar-multi' : ''">
												<span class="menu-link">
													<span class="menu-icon">
														<i class="ki-duotone ki-address-book fs-2  small-sidebar-icon">
															<span class="path1"></span>
															<span class="path2"></span>
															<span class="path3"></span>
														</i>
													</span>
													<span class="menu-title">Profile</span>
													<span class="menu-arrow"></span>
												</span>
												<div class="menu-sub menu-sub-accordion">
													<div class="menu-item">
														<a class="menu-link " href="profile">
															<span class="menu-bullet"><span class="bullet bullet-dot"></span></span>
															<span class="menu-title">View & Update Profile</span>
														</a>
													</div>
													<div class="menu-item">
														<a class="menu-link active" href="change_password">
															<span class="menu-bullet"><span class="bullet bullet-dot"></span></span>
															<span class="menu-title">Change Password</span>
														</a>
													</div>	
												</div>
											</div>
											<div class="menu-item small-sidebar" *ngIf="smallSidebar">
												<a class="menu-link " href="profile" title="View & Update Profile">
													<span class="menu-icon">
														<i class="ki-duotone ki-abstract-41 fs-2 small-sidebar-icon">
															<span class="path1"></span>
															<span class="path2"></span>
															<span class="path3"></span>
														</i>
													</span>
												</a>
											</div>
											<div class="menu-item small-sidebar" *ngIf="smallSidebar">
												<a class="menu-link active" href="change_password" title="Change Password">
													<span class="menu-icon">
														<i class="ki-duotone ki-abstract-41 fs-2 small-sidebar-icon">
															<span class="path1"></span>
															<span class="path2"></span>
															<span class="path3"></span>
														</i>
													</span>
												</a>
											</div>
											<div class="menu-item" [ngClass]="smallSidebar ? 'small-sidebar' : ''">
												<a class="menu-link" href="project_list" title="Create Project">
													<span class="menu-icon">
														<i class="ki-duotone ki-abstract-41 fs-2 small-sidebar-icon">
															<span class="path1"></span>
															<span class="path2"></span>
															<span class="path3"></span>
														</i>
													</span>
													<span class="menu-title">Create Project</span>
												</a>
											</div>
											<div data-kt-menu-trigger="click" class="menu-item menu-accordion" [ngClass]="smallSidebar ? 'small-sidebar-multi' : ''">
												<span class="menu-link">
													<span class="menu-icon">
														<i class="ki-duotone ki-address-book fs-2 small-sidebar-icon">
															<span class="path1"></span>
															<span class="path2"></span>
															<span class="path3"></span>
														</i>
													</span>
													<span class="menu-title">Inspection Settings</span>
													<span class="menu-arrow"></span>
												</span>
												<div class="menu-sub menu-sub-accordion">
													<div class="menu-item">
														<a class="menu-link" href="user_list">
															<span class="menu-bullet"><span class="bullet bullet-dot"></span></span>
															<span class="menu-title">User Registration</span>
														</a>
													</div>
													<div class="menu-item">
														<a class="menu-link" href="assign_project">
															<span class="menu-bullet"><span class="bullet bullet-dot"></span></span>
															<span class="menu-title">Assign Projects</span>
														</a>
													</div>	
												</div>
											</div>
											<div class="menu-item small-sidebar" *ngIf="smallSidebar">
												<a class="menu-link" href="user_list" title="User Registration">
													<span class="menu-icon">
														<i class="ki-duotone ki-abstract-41 fs-2 small-sidebar-icon">
															<span class="path1"></span>
															<span class="path2"></span>
															<span class="path3"></span>
														</i>
													</span>
												</a>
											</div>
											<div class="menu-item small-sidebar" *ngIf="smallSidebar">
												<a class="menu-link" href="assign_project" title="Assign Projects">
													<span class="menu-icon">
														<i class="ki-duotone ki-abstract-41 fs-2 small-sidebar-icon">
															<span class="path1"></span>
															<span class="path2"></span>
															<span class="path3"></span>
														</i>
													</span>
												</a>
											</div>
											<div class="menu-item" [ngClass]="smallSidebar ? 'small-sidebar' : ''">
												<a class="menu-link" href="fund_list" title="Fund Entry Form">
													<span class="menu-icon">
														<i class="ki-duotone ki-credit-cart fs-2 small-sidebar-icon">
															<span class="path1"></span>
															<span class="path2"></span>
															<span class="path3"></span>
														</i>
													</span>
													<span class="menu-title">Fund Entry Form</span>
												</a>
											</div>
											<div class="menu-item" [ngClass]="smallSidebar ? 'small-sidebar' : ''">
												<a class="menu-link" href="agency_fund_list" title="Fund Acknowledgement">
													<span class="menu-icon">
														<i class="ki-duotone ki-credit-cart fs-2 small-sidebar-icon">
															<span class="path1"></span>
															<span class="path2"></span>
															<span class="path3"></span>
														</i>
													</span>
													<span class="menu-title">Fund Acknowledgement</span>
												</a>
											</div>
											<div data-kt-menu-trigger="click" class="menu-item menu-accordion" [ngClass]="smallSidebar ? 'small-sidebar-multi' : ''">
												<span class="menu-link">
													<span class="menu-icon">
														<i class="ki-duotone ki-address-book fs-2">
															<span class="path1"></span>
															<span class="path2"></span>
															<span class="path3"></span>
														</i>
													</span>
													<span class="menu-title">Masters</span>
													<span class="menu-arrow"></span>
												</span>
												<div class="menu-sub menu-sub-accordion">
													<div class="menu-item">
														<a class="menu-link" href="district_list">
															<span class="menu-bullet"><span class="bullet bullet-dot"></span></span>
															<span class="menu-title">District Management</span>
														</a>
													</div>
													<div class="menu-item">
														<a class="menu-link" href="block_list">
															<span class="menu-bullet"><span class="bullet bullet-dot"></span></span>
															<span class="menu-title">Block Management</span>
														</a>
													</div>	
													<div class="menu-item">
														<a class="menu-link" href="stage_master">
															<span class="menu-bullet"><span class="bullet bullet-dot"></span></span>
															<span class="menu-title">Stage Management</span>
														</a>
													</div>	
													<div class="menu-item">
														<a class="menu-link" href="activity_master">
															<span class="menu-bullet"><span class="bullet bullet-dot"></span></span>
															<span class="menu-title">Activity Management</span>
														</a>
													</div>	
												</div>
											</div>
											<div class="menu-item small-sidebar" *ngIf="smallSidebar">
												<a class="menu-link" href="district_list" title="District Management">
													<span class="menu-icon">
														<i class="ki-duotone ki-abstract-41 fs-2 small-sidebar-icon">
															<span class="path1"></span>
															<span class="path2"></span>
															<span class="path3"></span>
														</i>
													</span>
												</a>
											</div>
											<div class="menu-item small-sidebar" *ngIf="smallSidebar">
												<a class="menu-link" href="block_list" title="Block Management">
													<span class="menu-icon">
														<i class="ki-duotone ki-abstract-41 fs-2 small-sidebar-icon">
															<span class="path1"></span>
															<span class="path2"></span>
															<span class="path3"></span>
														</i>
													</span>
												</a>
											</div>
											<div class="menu-item small-sidebar" *ngIf="smallSidebar">
												<a class="menu-link" href="stage_master" title="Stage Management">
													<span class="menu-icon">
														<i class="ki-duotone ki-abstract-41 fs-2 small-sidebar-icon">
															<span class="path1"></span>
															<span class="path2"></span>
															<span class="path3"></span>
														</i>
													</span>
												</a>
											</div>
											<div class="menu-item small-sidebar" *ngIf="smallSidebar">
												<a class="menu-link" href="activity_master" title="Activity Management">
													<span class="menu-icon">
														<i class="ki-duotone ki-abstract-41 fs-2 small-sidebar-icon">
															<span class="path1"></span>
															<span class="path2"></span>
															<span class="path3"></span>
														</i>
													</span>
												</a>
											</div>
											<div data-kt-menu-trigger="click" class="menu-item menu-accordion"  [ngClass]="smallSidebar ? 'small-sidebar-multi' : ''">
												<span class="menu-link">
													<span class="menu-icon">
														<i class="ki-duotone ki-address-book fs-2">
															<span class="path1"></span>
															<span class="path2"></span>
															<span class="path3"></span>
														</i>
													</span>
													<span class="menu-title">Reports</span>
													<span class="menu-arrow"></span>
												</span>
												<div class="menu-sub menu-sub-accordion">
													<div class="menu-item">
														<a class="menu-link" href="project_report">
															<span class="menu-bullet"><span class="bullet bullet-dot"></span></span>
															<span class="menu-title">Project Report</span>
														</a>
													</div>
												</div>
											</div>
											<div class="menu-item small-sidebar" *ngIf="smallSidebar">
												<a class="menu-link" href="project_report" title="Project Report">
													<span class="menu-icon">
														<i class="ki-duotone ki-abstract-41 fs-2 small-sidebar-icon">
															<span class="path1"></span>
															<span class="path2"></span>
															<span class="path3"></span>
														</i>
													</span>
												</a>
											</div>
											<div class="menu-item" [ngClass]="smallSidebar ? 'small-sidebar' : ''">
												<a class="menu-link" href="logout" title="Logout">
													<span class="menu-icon">
														<i class="ki-duotone ki-chart fs-2 small-sidebar-icon">
															<span class="path1"></span>
															<span class="path2"></span>
															<span class="path3"></span>
														</i>
													</span>
													<span class="menu-title">Logout</span>
												</a>
											</div><br><br><br><br><br><br><br>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<button id="kt_aside_toggle" class="aside-toggle btn btn-sm btn-icon bg-body btn-color-gray-700 btn-active-primary position-absolute translate-middle start-100 end-0 bottom-0 shadow-sm d-none d-lg-flex mb-5" data-kt-toggle="true" data-kt-toggle-target="body" data-kt-toggle-name="aside-minimize" (click)="customJS()">
				<i class="ki-duotone ki-arrow-left fs-2 rotate-180">
					<span class="path1"></span>
					<span class="path2"></span>
				</i>
			</button>
		</div>
		<div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper" [ngClass]="btnclose ? 'aside-close' : ''">
			<div id="kt_header" class="header" data-kt-sticky="true" data-kt-sticky-name="header" data-kt-sticky-offset="{default: '100px', lg: '100px'}">
			</div>
			<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
				<div class="container-xxl" id="kt_content_container">
					<div class="card mb-5 mb-xl-10">
						<div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
							<div class="card-title m-0">
								<h3 class="fw-bold m-0">Change Password</h3>
							</div>
						</div>
						<div id="kt_account_settings_profile_details" class="collapse show">
							<form id="kt_account_profile_details_form" class="form" [formGroup]="changePasswordForm" (ngSubmit)="changePassword($event)">
								<div class="card-body border-top p-9">
									<div class="row mb-6">
										<label class="col-lg-4 required col-form-label fw-semibold fs-6">Current Password</label>
										<div class="col-lg-8 fv-row">
											<input type="password" formControlName="password" class="form-control form-control-lg form-control-solid" placeholder="Current Password" [ngClass]="{'inputError': f.password.touched && f.password.invalid && f.password.errors && f.password.errors.required }"
											[ngClass]="{'inputError': formErrorMessages.passwordError }" />
										</div>
									</div>
									<div class="row mb-6">
										<label class="col-lg-4 required col-form-label fw-semibold fs-6">New Password</label>
										<div class="col-lg-8 fv-row">
											<input type="password" formControlName="new_password" class="form-control form-control-lg form-control-solid" placeholder="New Password"  [ngClass]="{'inputError': f.new_password.touched && f.new_password.invalid && f.new_password.errors && f.new_password.errors.required }"
											[ngClass]="{'inputError': formErrorMessages.new_passwordError }" />
											<span>Passwod should be one lowercase letter, one uppercase letter, one digit, one special character, and is at least eight characters long.</span>
										</div>
									</div>
									<div class="row mb-6">
										<label class="col-lg-4 required col-form-label fw-semibold fs-6">Confirm New Password</label>
										<div class="col-lg-8 fv-row">
											<input type="password" formControlName="c_new_password" class="form-control form-control-lg form-control-solid" placeholder="Confirm New Password" [ngClass]="{'inputError': f.c_new_password.touched && f.c_new_password.invalid && f.c_new_password.errors && f.c_new_password.errors.required }"
											[ngClass]="{'inputError': formErrorMessages.c_new_passwordError }"/>
										</div>
									</div>
									<div *ngIf="errorMessage">
										<div class="alert alert-danger" role="alert">
											{{errorMessage}}
										</div>
									</div>
									<div *ngIf="successMessage">
										<div class="alert alert-success" role="alert">
											{{successMessage}}
										</div>
									</div>
								</div>
								<div class="card-footer d-flex justify-content-end py-6 px-9">
									<a href="dashboard" class="btn btn-light btn-active-light-primary me-2">Discard</a>
									<button type="submit" class="btn btn-primary" id="kt_account_profile_details_submit">Update</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<div class="footer py-4 d-flex flex-lg-column " id="kt_footer">
				<div class=" container-xxl  d-flex flex-column flex-md-row flex-stack">
					<div class="text-dark order-2 order-md-1">
						<span class="text-gray-400 fw-semibold me-1">Nirmanalekh - Tribal Welfare Department, Madhya Pradesh</span>
					</div>
				</div>
			</div>
<div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
<i class="ki-duotone ki-arrow-up">
<span class="path1"></span>
<span class="path2"></span>
</i>
</div>