import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as $ from 'jquery';

import { BusinessMaster } from '../models/businessMaster';

import { BusinessMasterService } from "../services/businessMaster.service";
import { RoleService } from "../services/role.service";

@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: [
		'./profile.component.css',
		'../../assets/css/material-dashboard.css?v=3.1.0',
		'../../assets/css/responsive.css',
	]
})
export class ProfileComponent implements OnInit {
	
	validPattern = "^[a-zA-Z0-9]{30}$"; // alphanumeric exact 10 letters

	profileForm = new FormGroup({
		role_type: new FormControl('', [Validators.required]),
		name: new FormControl('', [Validators.required, Validators.pattern(this.validPattern)]),
		myaddress: new FormControl('', [Validators.required]),
		email: new FormControl('', [Validators.required]),
		phone: new FormControl('', [Validators.required]),
		e_phone: new FormControl('', [Validators.required]),
		c_name: new FormControl('', [Validators.required]),
		c_email: new FormControl('', [Validators.required]),
		c_phone: new FormControl('', [Validators.required]),
		about: new FormControl('', [Validators.required]),
	});

	get f() {
		return this.profileForm.controls;
	}
	
	roleList: any;
	user_id = 0;
	role_type = 0;

	title: any;
	previousBtn = false;
	nextBtn = true;
	submitBtn = false;
	step1 = true;
	step2 = false;
	step3 = false;
	step4 = false;
	step5 = false;
	step6 = false;
	step7 = false;
	nextbtnClick = '';
	previousbtnClick = '';
	errorMessage = '';
	successMessage = '';
	formErrorMessages = {
		role_typeError: '',
		nameError: '',
		addressError: '',
		emailError: '',
		phoneError: '',
		e_phoneError: '',
		c_nameError: '',
		c_emailError: '',
		c_phoneError: '',
		aboutError: ''
	};

	constructor(
		private businessMasterService: BusinessMasterService,
		private roleService: RoleService,
		private router: Router,
	) {}

	ngOnInit() {
		var currentUser = localStorage.getItem('currentUser');
		if (currentUser) {
			var userArray = JSON.parse(currentUser);
			this.user_id = userArray.data.id;
			this.role_type = userArray.data.role_type;
			if(this.role_type){
				setTimeout(() => {
					this.router.navigate(['dashboard']);
				}, 100);
			}
			this.getRoleType();
		}
	}

	getRoleType(): void {
		this.roleList = [];

		this.roleService.getRole().subscribe(
		(data) => {
			this.roleList = data.data;
		});
	}

	onChangeSelect($event: any){
		let text = $event.target.options[$event.target.options.selectedIndex].text;
		this.title = text;
	}

	handleFormValidation($event: any): void{
		let role_type = this.profileForm.value.role_type.trim();
		let name = this.profileForm.value.name.trim();
		let address = $('#address').val();
		let email = this.profileForm.value.email.trim();
		let phone = this.profileForm.value.phone.trim();
		let e_phone = this.profileForm.value.e_phone.trim();
		let c_name = this.profileForm.value.c_name.trim();
		let c_email = this.profileForm.value.c_email.trim();
		let c_phone = this.profileForm.value.c_phone.trim();
		let about = this.profileForm.value.about.trim();
		this.submitBtn = false;

		if(this.step1){
			if (!role_type) {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = 'Role type is required';
				errorMessage?.click();
			} else {
				this.formErrorMessages.role_typeError = '';
				this.previousBtn = true;
				this.step1 = false;
				this.step2 = true;
				this.nextbtnClick = 'nextbtnClick';
				return;
			}
		}
		if(this.step2){
			if (!name) {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				errorMessage?.click();
			} else {
				this.formErrorMessages.nameError = '';
				this.previousBtn = true;
				this.step2 = false;
				this.step3 = true;
				this.nextbtnClick = '';
				return;
			}
		}
		if(this.step3){
			if (!address) {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				errorMessage?.click();
			} else {
				this.formErrorMessages.addressError = '';
				this.previousBtn = true;
				this.step3 = false;
				this.step4 = true;		
				return;
			}
		}
		if(this.step4){
			if (!email) {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				errorMessage?.click();
			} else {
				this.formErrorMessages.emailError = '';
				this.previousBtn = true;
				this.step4 = false;
				this.step5 = true;		
				return;
			}
		}
		if(this.step5){
			if (!phone) {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				errorMessage?.click();
			} else {
				this.formErrorMessages.phoneError = '';
				this.previousBtn = true;
				this.step5 = false;
				this.step6 = true;
				return;
			}
			if (!e_phone) {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				errorMessage?.click();
			} else {
				this.formErrorMessages.e_phoneError = '';
				this.previousBtn = true;
				this.step5 = false;
				this.step6 = true;
				return;
			}
		}
		if(this.step6){
			if (!c_name) {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				errorMessage?.click();
			} else {
				this.formErrorMessages.c_nameError = '';
				this.previousBtn = true;
				this.step6 = false;
				this.step7 = true;
				this.nextBtn = false;
				this.submitBtn = true;
				return;
			}
			if (!c_email) {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				errorMessage?.click();
			} else {
				this.formErrorMessages.c_emailError = '';
				this.previousBtn = true;
				this.step6 = false;
				this.step7 = true;
				this.nextBtn = false;
				this.submitBtn = true;
				return;
			}
			if (!c_phone) {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				errorMessage?.click();
			} else {
				this.formErrorMessages.c_phoneError = '';
				this.previousBtn = true;
				this.step6 = false;
				this.step7 = true;
				this.nextBtn = false;
				this.submitBtn = true;
				return;
			}
		}
		if(this.step7){
			this.submitBtn = true;
			if (!about) {
				// const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				// errorMessage?.click();
			} else {
				this.formErrorMessages.aboutError = '';
				this.previousBtn = true;
			}
		}
		return;
	}

	handlePrevBtn($event: any): void{
		if(this.step2){
			this.previousBtn = false;
			this.step2 = false;
			this.step1 = true;
			return;
		}
		if(this.step3){
			this.previousBtn = true;
			this.step3 = false;
			this.step2 = true;		
			return;
		}
		if(this.step4){
			this.previousBtn = true;
			this.step4 = false;
			this.step3 = true;		
			return;
		}
		if(this.step5){
			this. previousBtn = true;
			this. step5 = false;
			this. step4 = true;
			return;
		}
		if(this.step6){
			this.previousBtn= true;
			this.step6 = false;
			this.step5 = true;
			return;
		}
		if (this.step7){
			this.previousBtn = true;
			this.nextBtn = true;
			this.submitBtn = false;
			this.step7 = false;
			this.step6 = true;
			return;

		}
		return;
	}

	profile($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;

		let role_type = this.profileForm.value.role_type.trim();
		let name = this.profileForm.value.name.trim();
		let email = this.profileForm.value.email.trim();
		let phone = this.profileForm.value.phone.trim();
		let e_phone = this.profileForm.value.e_phone.trim();
		let c_name = this.profileForm.value.c_name.trim();
		let c_email = this.profileForm.value.c_email.trim();
		let c_phone = this.profileForm.value.c_phone.trim();
		let about = this.profileForm.value.about.trim();
		let address = $('#address').val();
		let latitude = $('#latitude').val();
		let longitude = $('#longitude').val();
		
		let user_id = 0;
		var currentUser = localStorage.getItem('currentUser');
		if (currentUser) {
			var userArray = JSON.parse(currentUser);
			user_id = userArray.data.id;
		}

		this.businessMasterService.addBusinessMaster({ 
			name,
			address,
			email,
			phone,
			e_phone,
			c_name,
			c_email,
			c_phone,
			about,
			latitude,
			longitude,
			user_id,
			role_type
			} as BusinessMaster)
			.subscribe(
				data => {
					this.errorMessage = '';
					if (data.status == 200) {
						this.successMessage = data.message;
						
						let retrieved1String = localStorage.getItem("currentUser");
						if (retrieved1String) {
							let parsedObject = JSON.parse(retrieved1String);
							parsedObject.data.role_type = role_type;
							const modifiedndstrigifiedForStorage = JSON.stringify(parsedObject);
							localStorage.setItem("currentUser", modifiedndstrigifiedForStorage);
						}

						setTimeout(() => {
							this.router.navigate(['thankyou']);
						}, 2000);
					} else {
						this.errorMessage = data.message;
						($event.submitter as HTMLButtonElement).disabled = false;
					}
				},
				error => {
					this.errorMessage = error;
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			);

	}
}
