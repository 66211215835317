<body class="g-sidenav-show bg-gray-200">
	<aside class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3   bg-gradient-whi" id="sidenav-main">
		<div class="sidenav-header">
		  	<i class="fas fa-times p-3 cursor-pointer text-white opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav"></i>
		  	<a class="navbar-brand m-0" href="dashboard" target="_blank">
				<img src="assets/img/logo.png" class="navbar-brand-img h-100" alt="main_logo">
		  	</a>
		</div>
		<hr class="horizontal light mt-0 mb-2">
		<div class="collapse navbar-collapse  w-auto " id="sidenav-collapse-main">
		  	<ul class="navbar-nav">
		 		<li class="nav-item">
					<a class="nav-link text-darkk" *ngIf="!b_id" href="dashboard">
						<div class="text-darkk text-center me-2 d-flex align-items-center justify-content-center">
							<i class="material-icons opacity-10">home</i>
						</div>
						<span class="nav-link-text ms-1">Home</span>
			  		</a>
					<a class="nav-link text-darkk" *ngIf="b_id" href="dashboard?b_id={{b_id}}">
						<div class="text-darkk text-center me-2 d-flex align-items-center justify-content-center">
							<i class="material-icons opacity-10">home</i>
						</div>
						<span class="nav-link-text ms-1">Home</span>
			  		</a>
				</li>
				<li class="nav-item">
					<a class="nav-link active  text-white bg-gradient-primary" *ngIf="!b_id" href="gallery">
						<div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
						  <i class="material-icons opacity-10">image</i>
						</div>
						<span class="nav-link-text ms-1">Add Gallery</span>
					</a>
					<a class="nav-link active  text-white bg-gradient-primary" *ngIf="b_id" href="gallery?b_id={{b_id}}">
						<div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
							<i class="material-icons opacity-10">image</i>
						</div>
						<span class="nav-link-text ms-1">Add Gallery</span>
					</a>
			  	</li>
		  	</ul>
		</div>
		<div class="sidenav-footer position-absolute w-100 bottom-0 ">
		  	<div class="mx-3">
				<a class="btn bg-gradient-primary w-100" *ngIf="detailedList" href="profile_new/{{detailedList.details.id}}" type="button">Add new Business</a>
		  	</div>
		</div>
	</aside>
  	<div class="main-content position-relative max-height-vh-100 h-100">
    	<nav class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur" data-scroll="true">
      		<div class="container-fluid py-1 px-3 navb">
        		<nav aria-label="breadcrumb">
        			<h2 class="welcome-text">Welcome to Donorzilla</h2>
        		</nav>
        		<div class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
          			<div class="ms-md-auto pe-md-3 d-flex align-items-center">
						<span class="select_wrap">
							<select *ngIf="businessList" (change)="onChange($event)">
								<option value="{{business.id}}" *ngFor="let business of businessList;let i = index" [selected]="business.id == this.b_id">{{business.name}}</option>
							</select>
						</span>
             			<div class="help">
							<ul class="notification-drop">
								<li class="item">
									<i class="fa fa-bell notification-bell" aria-hidden="true"></i>
									<span class="btn__badge">{{notificationCounter}}</span>
									<span class="pulse-button"></span>
									<div class="notificandrop">
										<ul>
											<div class="notify-head">
												<div class="row">
													<div class="col-md-6">
														<h3 class="head-not">Notifications</h3>
													</div>
													<div class="col-md-6">
														<a href="javascript:;" (click)="updateNotification()" class="head-link">Mark all as read</a>
													</div>
												</div>
											</div>
											<div class="notify-scroll">
												<li class="list-notification {{notification.status ? 'color-white' : 'color-grey'}}" *ngFor="let notification of notificationList;let i = index">
													<div class="notification-inner">
														<div class="notify-text">
															<p>{{notification.message}}</p>
														</div>
														<!-- <div class="notify-time">
															<p>2 min ago</p>
														</div> -->
													</div>
												</li>
											</div>
										</ul>
									</div>
								</li>
							</ul>
            			</div>
            			<div class="help">
              				<a href=""><i class="fa fa-question-circle" aria-hidden="true"></i></a>
           	 			</div>
						<div class="help">
							<a href="logout"><i class="fa fa-sign-out" aria-hidden="true"></i></a>
						</div>
          			</div>
        		</div>
      		</div>
    	</nav>

    	<div class="container-fluid px-2 px-md-4" style="margin-top: 60px;" *ngIf="detailedList">
      		<div class="card card-body mx-3 mx-md-4 mt-n6">
				<form [formGroup]="addBusinessForm" (ngSubmit)="addBusinessImages($event)" class="form" encType="multipart/form-data">
					<div class="row">
						<div class="col-md-5">
							<label for="images" class="drop-container" id="dropcontainer">
								<span class="drop-title">Click here to upload files</span>
								<input #imageInput class="form-control form-control-solid" type="file" accept="image/*" (change)="processFile(imageInput)" multiple="" />
							</label>
						</div>
						<div class="col-md-7">
							<img *ngFor='let url of images'  [src]="url" height="150" width="200px" style="margin: 3px;">
						</div>
					</div>
					<div class="submit-image">
						<button class="btn bg-gradient-primary w-100" type="submit">Submit</button>
					</div>
				</form>
				<div class="row">
					<div class="col-md-12">
						<h3>Recent Uploaded Images</h3>
						<img *ngFor='let image of imageList'  [src]="image.image" height="150" width="200px" style="margin: 3px;">
					</div>
				</div>
      		</div>
    	</div>
		<footer class="footer py-4  ">
			<div class="container-fluid">
				<div class="row align-items-center justify-content-lg-between">
					<div class="col-lg-12 mb-lg-0 mb-4">
						<div class="copyright text-center text-sm ">
							Donorzilla &copy;2024, All Rights Reserved.
						</div>
					</div>
				</div>
			</div>
		</footer>
  	</div>
