import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-public-profile',
	templateUrl: './public-profile.component.html',
	styleUrls: [
		'./public-profile.component.css',
		'../../assets/home_assets/css/bootstrap.min.css',
		'../../assets/home_assets/css/animate.css',
		'../../assets/home_assets/css/all.css',
		'../../assets/home_assets/css/slick.css',
		'../../assets/home_assets/css/fancybox.css',
		'../../assets/home_assets/css/style.css',
		'../../assets/home_assets/css/business.css',
		'../../assets/home_assets/css/themify-icons.css',
		'../../assets/home_assets/css/aos.css',
	]
})
export class PublicProfileComponent implements OnInit {

	constructor(
	) { }

	ngOnInit(): void {
	}

}