import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { CommonService } from '../services/common.service';
import { User } from '../models/user';

@Component({
	selector: 'app-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: [
		
	]
})
export class ChangePasswordComponent implements OnInit {

	changePasswordForm = new FormGroup({
		password: new FormControl("", [Validators.required]),
		new_password: new FormControl("", [Validators.required]),
		c_new_password: new FormControl("", [Validators.required]),
		userId: new FormControl("", [Validators.required]),
  	});

	get f() {
		return this.changePasswordForm.controls;
	}

	userList: any;
	userById: any;
	btnclose: boolean = false;
	smallSidebar: boolean = false;
	profileDIv: boolean = false;
	currentUserList: any;
	currentUserData: any;

	errorMessage = "";
	successMessage = "";
	formErrorMessages = {
		passwordError: "",
		new_passwordError: "",
		c_new_passwordError: "",
	};
	constructor(
		private commonService: CommonService,
		private router: Router,
	) { }

	ngOnInit(): void {
		let userData: any;
		userData = localStorage.getItem('currentUser');
		let userArray = JSON.parse(userData);
		let userId = userArray.data.id;
		this.changePasswordForm.controls["userId"].setValue(userId);

		this.currentUserData = localStorage.getItem('currentUser');
		this.currentUserList = JSON.parse(this.currentUserData);
	}

	customJS(): void{
		if(this.btnclose){
			this.btnclose = !this.btnclose;
			this.smallSidebar = !this.smallSidebar;
		} else{
			this.btnclose = true;
			this.smallSidebar = true;
		}
	}

	openProfileModel(): void{
		this.profileDIv = !this.profileDIv;
	}
	
	changePassword($event: any): void {
		($event.submitter as HTMLButtonElement).disabled = true;
		let password = this.changePasswordForm.value.password.trim();
		let new_password = this.changePasswordForm.value.new_password.trim();
		let c_new_password = this.changePasswordForm.value.c_new_password.trim();
		
		let userId = this.changePasswordForm.value.userId;
		if (
		!password ||
		!new_password ||
		!c_new_password ||
		!userId
		) {
		if (!password) {
			this.formErrorMessages.passwordError = "Password field is required.";
		} else {
			this.formErrorMessages.passwordError = "";
		}
		if (!new_password) {
			this.formErrorMessages.new_passwordError = "New Password field is required.";
		} else {
			this.formErrorMessages.new_passwordError = "";
		}
		if (!c_new_password) {
			this.formErrorMessages.c_new_passwordError = "Confirm New Password field is required.";
		} else {
			this.formErrorMessages.c_new_passwordError = "";
		}
		this.errorMessage = "All fields are required.";
		($event.submitter as HTMLButtonElement).disabled = false;
		return;
		}
		($event.submitter as HTMLButtonElement).disabled = true;
		this.commonService
		.changePassword({
			password,
			new_password,
			c_new_password,
			userId
		} as User)
		.subscribe(
			(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
				this.successMessage = data.message;
				let userData: any;
				userData = localStorage.getItem('currentUser');
				let userArray = JSON.parse(userData);
				userArray.data.is_verify = '1';
				localStorage.setItem("user",userArray);
				localStorage.setItem('currentUser', JSON.stringify(userArray));
				setTimeout(() => {
					this.router.navigate(['dashboard']);
				}, 2000);
			} else {
				this.errorMessage = data.message;
				($event.submitter as HTMLButtonElement).disabled = false;
			}
			},
			(error) => {
				this.errorMessage = error;
				($event.submitter as HTMLButtonElement).disabled = false;

			}
		);
	}
}