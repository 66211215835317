import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PublicProfileComponent } from './public-profile.component';

const routes: Routes = [
  {
    path: 'public_profile',
    component: PublicProfileComponent,
    data: { showHeader: false, showSidebar: false, showFooter: false }
  }
]; 

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PublicProfileRoutingModule { }
