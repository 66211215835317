<header id="top-header">
    <div class="container">
        <div class="logo-header">
            <img src="assets/img/logo.png" class="text-center">
        </div>
    </div>
</header>
<div class="login-container">
    <div class="onboarding">
        <div class="images-signin">
            <img src="assets/img/login.png">    
        </div>
    </div>
    <div class="login-form">
        <div class="login-form-inner">
            <form [formGroup]="loginForm" (ngSubmit)="login($event)" *ngIf="!otpSent">
                <h1 class="align-center">Login</h1>
                <div class="sign-in-seperator">
                    <span>Sign in with Phone</span>
                </div>
                <div class="login-form-group">
                    <label for="phone">Phone <span class="required-star">*</span></label>
                    <input type="text" onkeypress="return event.charCode >= 48 && event.charCode <= 57" minlength="10" maxlength="10" placeholder="Phone" formControlName="phone" autocomplete="off" [ngClass]="{'inputError': f.phone.touched && f.phone.invalid && f.phone.errors && f.phone.errors.required }" [ngClass]="{'inputError': formErrorMessages.phoneError }">
                </div>
                <div class="login-form-group single-row">
                    <div class="custom-check">
                        <input autocomplete="off" type="checkbox" checked id="remember"><label for="remember">Remember me</label>
                    </div>
                </div>
                <div *ngIf="errorMessage">
                    <div class="alert alert-danger" role="alert">
                        {{errorMessage}}
                    </div>
                </div>
                <div class="send-otp">
                    <button type="submit" class="rounded-button login-cta">Send Otp</button>
                </div>
                <div class="register-div">Not registered yet? <a href="register" class="link create-account">Create an account ?</a></div>
            </form>
            <form [formGroup]="otpForm" (ngSubmit)="verifyOtp($event)" *ngIf="otpSent">
                <h1 class="align-center">Verify OTP</h1>
                <div class="row justify-content-center">
                    <div class="col-12 col-md-6 col-lg-4 otp-submit">
                        <div class="card bg-white mb-5 border-0" style="box-shadow: 0 12px 15px rgba(0, 0, 0, 0.02);">
                            <div class="card-body  text-center">
                                <div class="mobile-number">
                                    <div class="row">
                                        <div class="col-md-7">
                                            <div class="mobile-no">
                                                <p>Mobile Number</p>
                                                <span>{{requestPhone}}</span>
                                                <input type="hidden" formControlName="verifyPhone" autocomplete="off">
                                            </div>
                                        </div>
                                        <div class="col-md-5">
                                            <div class="edit-mobile">
                                                <a href="login">Edit</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p>Enter OTP</p>
                                <div class="otp-field mb-4">
                                    <input type="number" placeholder="-" formControlName="otp1" autocomplete="off" />
                                    <input type="number" placeholder="-" formControlName="otp2" autocomplete="off" />
                                    <input type="number" placeholder="-" formControlName="otp3" autocomplete="off" />
                                    <input type="number" placeholder="-" formControlName="otp4" autocomplete="off" />
                                </div>
                                <p>Time Left: {{display }} seconds</p>
                                <div *ngIf="errorMessage">
                                    <div class="alert alert-danger" role="alert">
                                        {{errorMessage}}
                                    </div>
                                </div>
                                <div class="send-otp">
                                    <button type="submit" class="rounded-button login-cta">Submit</button>
                                </div>
                                <p class="resend text-muted mb-0" *ngIf="resendEnable">
                                    Didn't receive code? <a href="javascript:;" (click)="resendOtp($event, requestPhone)">Request again</a>
                                </p>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<footer class="footer-bottom">
    <div>Donorzilla &copy;2024, All Rights Reserved.</div>
</footer>
