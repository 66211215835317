<div class="progress-wrap cursor-pointer active-progress">
    <svg class="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" style="transition: stroke-dashoffset 10ms linear 0s; stroke-dasharray: 307.919, 307.919; stroke-dashoffset: 221.377;">
        </path>
    </svg>
</div>
<header>
    <div class="header_top d-none d-lg-block d-xl-block d-xxl-block">
        <div class="container">
            <div class="row">
                <div class="col-xl-3 col-lg-3">
                    <div class="header_top_content">
                        <span><i class="fa-solid fa-envelope"></i></span>
                        <a href="mailto:company@domin.com">contact@donorzilla.com</a>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3"></div>
                <div class="col-xl-2 col-lg-2"></div>
                <div class="col-xl-4 col-lg-4">
                    <div class="header_top_social">
                        <p>Follow Now</p>
                        <ul class="d-flex">
                            <li><a href="https://www.facebook.com/donorzilla/" target="_blank"><i class="fa-brands fa-facebook-f"></i></a></li>
                            <li><a href="https://twitter.com/DonorZilla" target="_blank"><i class="fa-brands fa-twitter"></i></a></li>
                            <li><a href=" https://www.instagram.com/donorzilla/" target="_blank"><i class="fa-brands fa-instagram"></i></a></li>
                            <li><a href="https://www.pinterest.com/donorzilla/" target="_blank"><i class="fa-brands fa-pinterest-p"></i></a></li>
                            <li><a href="https://www.youtube.com/@donorzilla" target="_blank"><i class="fa-brands fa-youtube"></i></a></li>
                            <li><a href="https://www.linkedin.com/company/donorzilla/" target="_blank"><i class="fa-brands fa-linkedin"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="header_bottom">
        <div class="container">
            <div class="row align-items-center position-relative">
                <div class="col-xl-2 col-lg-2 col-md-4 col-6">
                    <div class="header_logo">
                        <a href=""><img src="assets/home_assets/images/logo.png" alt="images not founds" class="img-fluid"></a>
                    </div>
                </div>
                <div class="col-xl-10 col-lg-10 d-none d-xxl-block d-xl-block">
                    <ul class="main_menu">
                        <li class="position-relative">
                            <a href="#why">Why Donorzilla?</a>
                        </li>
                        <li><a href="#faq">Faq</a></li>
                        <li><a href="#blog">Blog</a></li>
                        <li><a href="#download">Download App</a></li>
                        <li><a href="#contact">Contact Us</a></li>
                    </ul>
                </div>
                <div class="col-lg-10 col-md-8 col-6 d-block d-xxl-none d-xl-none">
                    <div class="d-flex align-items-center gap-2 justify-content-end">
                        <div class="mobile_menu">
                            <button class="header_toggle_btn bg-transparent border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas-mobile">
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<section class="hm1_hero_slider">
    <div class="hm1_hero hm1 hm_bg">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="hm1_content">
                        <h3>Donate blood,save life !</h3>
                        <h1>Donate Blood And Inspires Others.</h1>
                        <div class="mobile-image">
                            <img src="assets/home_assets/images/app1.png">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- <section class="hm1_hero_slider">
    <div class="hm1_hero hm1 hm_bg">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="hm1_content">
                        <h3>Donate blood,save life !</h3>
                        <h1>Donate Blood And Inspires Others.</h1>
                        <a href="#" class="explore_now red_btn">Login <i class="fa fa-user-plus" aria-hidden="true"></i></a>
                        <a href="#" class="explore_now red_btn">Sign Up <i class="fa fa-sign-in" aria-hidden="true"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="hm1_hero hm13  hm_bg">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="hm1_content">
                        <h3>Donate blood,save life !</h3>
                        <h1>Donate Blood And Inspires Others.</h1>
                        <a href="#" class="explore_now red_btn">Login <i class="fa fa-user-plus" aria-hidden="true"></i></a>
                        <a href="#" class="explore_now red_btn">Sign Up <i class="fa fa-sign-in" aria-hidden="true"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="hm1_hero hm13  hm_bg">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="hm1_content">
                        <h3>Donate blood,save life !</h3>
                        <h1>Donate Blood And Inspires Others.</h1>
                        <a href="#" class="explore_now red_btn">Login <i class="fa fa-user-plus" aria-hidden="true"></i></a>
                        <a href="#" class="explore_now red_btn">Sign Up <i class="fa fa-sign-in" aria-hidden="true"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="hm1_hero hm13  hm_bg">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="hm1_content">
                        <h3>Donate blood,save life !</h3>
                        <h1>Donate Blood And Inspires Others.</h1>
                        <a href="#" class="explore_now red_btn">Login <i class="fa fa-user-plus" aria-hidden="true"></i></a>
                        <a href="#" class="explore_now red_btn">Sign Up <i class="fa fa-sign-in" aria-hidden="true"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<section class="register_donate ptb-115 gray">
    <div class="container">
        <div class="row g-0 register_top">
            <div class="col-xl-4 col-lg-4 col-12">
                <div class="register-btn">
                    <a href="#">Register As Donor</a>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-12">
                <div class="become-btn">
                    <a href="#">Find A Blood Donor</a>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-12">
                <div class="register-btn business-btn">
                   <a href="register">Register As A Business</a>
                </div>
            </div>
        </div>
        <img src="assets/home_assets/images/zig.png" class="zig-img">
        <img src="assets/home_assets/images/zig.png" class="zig-img1">
        <div id="why"></div>
        <div class="text-why">
            <h2 class="text-center">Why Donorzilla?</h2><br>
        </div><br>
        <div class="row justify-content-center">
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
                <div class="register_donate_item">
                    <div class="donate_item_top">
                        <div class="donate_content text-center">
                            <span><img src="assets/home_assets/images/icon/d1.png" alt=""></span>
                            <a href="#"><h5>Geo-Search</h5></a>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
                <div class="register_donate_item">
                    <div class="donate_item_top">
                        <div class="donate_content text-center">
                            <span><img src="assets/home_assets/images/icon/d2.png" alt=""></span>
                            <a href="#"><h5>Real-time Connect</h5></a>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                <div class="register_donate_item">
                    <div class="donate_item_top">
                        <div class="donate_content text-center">
                            <span><img src="assets/home_assets/images/icon/d3.png" alt=""></span>
                            <a href="#"><h5>Notifications</h5></a>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div><br>
        <div class="row justify-content-center">
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
                <div class="register_donate_item">
                    <div class="donate_item_top">
                        <div class="donate_content text-center">
                            <span><img src="assets/home_assets/images/icon/d1.png" alt=""></span>
                            <a href="#"><h5>Geo-Search</h5></a>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
                <div class="register_donate_item">
                    <div class="donate_item_top">
                        <div class="donate_content text-center">
                            <span><img src="assets/home_assets/images/icon/d2.png" alt=""></span>
                            <a href="#"><h5>Real-time Connect</h5></a>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                <div class="register_donate_item">
                    <div class="donate_item_top">
                        <div class="donate_content text-center">
                            <span><img src="assets/home_assets/images/icon/d3.png" alt=""></span>
                            <a href="#"><h5>Notifications</h5></a>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="help_people ptb-115 overflow-hidden">
    <div class="container">
        <div class="row align-items-center g-lg-5 g-xl-5 g-xxl-5 flex-column-reverse flex-lg-row">
            <div class="col-xl-6 col-lg-6 col-md-6 col-12">
                <div class="help_content">
                    <p class="red_color">Elevate Your Healthcare Visibility with DonorZilla!</p>
                    <p>List your Hospital, Blood Bank, or Pathology Lab and connect with thousands seeking healthcare services.</p>
                    <div class="d-flex justify-content-between">
                        <ul>
                            <h3 class="list-head">Benefits:</h3>
                            <li><i class="fa-solid fa-angles-right"></i> Enhanced Visibility</li>
                            <li><i class="fa-solid fa-angles-right"></i> Direct Patient Access</li>
                            <li><i class="fa-solid fa-angles-right"></i> Credibility & Trust</li>
                            <li><i class="fa-solid fa-angles-right"></i> 24/7 Availability</li>
                        </ul>
                        <ul>
                            <h3 class="list-head" style="min-height: 23px;"></h3>
                            <li><i class="fa-solid fa-angles-right"></i> Increased Engagement</li>
                            <li><i class="fa-solid fa-angles-right"></i> Real-Time Analytics</li>
                            <li><i class="fa-solid fa-angles-right"></i> Customer Reviews & Ratings</li>
                            <li><i class="fa-solid fa-angles-right"></i> Cost-Effective Marketing</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-12 mb-4 mb-xl-0 mb-lg-0 mb-md-0">
                <div class="help_wrap position-relative">
                    <img src="assets/home_assets/images/app1.png" alt="">
                </div>
            </div>
        </div>
    </div>
</section>
<section class="help_people ptb-115 overflow-hidden gray">
    <div class="container">
        <div class="row align-items-center g-lg-5 g-xl-5 g-xxl-5">
            <div class="col-xl-6 col-lg-6 col-md-6 col-12 mb-4 mb-xl-0 mb-lg-0 mb-md-0">
                <div class="help_wrap position-relative">
                    <img src="assets/home_assets/images/app1.png" alt="">
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-12">
                <div class="help_content">
                    <p class="red_color">Help The People in Need</p>
                    <h2>Welcome to Blood Donors Organization</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incididunt ut labore et dolore magna aliqua. suspendisse the gravida. Risus commodo viverra maecenas</p>
                    <div class="d-flex justify-content-between">
                        <ul>
                            <li><i class="fa-solid fa-angles-right"></i> Good Service</li>
                            <li><i class="fa-solid fa-angles-right"></i> Help People</li>
                            <li><i class="fa-solid fa-angles-right"></i> Hugine Tools</li>
                        </ul>
                        <ul>
                            <li><i class="fa-solid fa-angles-right"></i> 24h Service</li>
                            <li><i class="fa-solid fa-angles-right"></i> Health Check</li>
                            <li><i class="fa-solid fa-angles-right"></i> Blood Bank</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="download-section gray ptb-115">
    <div class="container">
        <div class="row justify-content-center">
            <div class="download-text">
                <h2>Download The App Now</h2>
                <div class="app-donate">
                    <!-- <img src="assets/home_assets/images/ios.png"> -->
                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.insight.donorzilla" ><img src="assets/home_assets/images/android.png"></a>
                </div>
            </div>
        </div>
    </div>
</section>
<div id="faq"></div>
<div class="accordion">
    <h1>FAQs</h1>
    <div class="accordion-item">
        <input type="checkbox" id="accordion1">
        <label for="accordion1" class="accordion-item-title"><span class="icon"></span>What is DonorZilla?</label>
        <div class="accordion-item-desc">DonorZilla is a mobile app bridging the gap between blood donors and patients requiring transfusions. It facilitates seamless connections, allowing donors to respond to urgent blood requests and schedule donations conveniently.</div>
    </div>
    <div class="accordion-item">
        <input type="checkbox" id="accordion2">
        <label for="accordion2" class="accordion-item-title"><span class="icon"></span>How does DonorZilla work?</label>
        <div class="accordion-item-desc">DonorZilla utilizes a user-friendly interface where individuals in need of blood can create requests, and registered donors can view these requests based on location and blood type compatibility. Donors can then choose to respond to requests and schedule donation appointments.</div>
    </div>
    <div class="accordion-item">
        <input type="checkbox" id="accordion3">
        <label for="accordion3" class="accordion-item-title"><span class="icon"></span>Is DonorZilla available for both donors and recipients?</label>
        <div class="accordion-item-desc">Yes, DonorZilla is accessible to both blood donors and patients in need of blood transfusions. Donors can sign up to offer blood donations, while patients can create requests for blood donations when needed.</div>
    </div>
    <div class="accordion-item">
        <input type="checkbox" id="accordion4">
        <label for="accordion4" class="accordion-item-title"><span class="icon"></span>Is DonorZilla free to use?</label>
        <div class="accordion-item-desc">Yes, DonorZilla is free to download and use for both donors and recipients. The recipient and donor do not need to spend any money to use the application</div>
    </div>
    <div class="accordion-item">
        <input type="checkbox" id="accordion5">
        <label for="accordion5" class="accordion-item-title"><span class="icon"></span>How can I register as a blood donor on DonorZilla?</label>
        <div class="accordion-item-desc">To register as a blood donor, simply download the DonorZilla app from the App Store or Google Play Store, create an account, and fill in your details, including blood type, location, and contact information. once all the details are filled you can accept requests or create your own.</div>
    </div>
    <div class="accordion-item">
        <input type="checkbox" id="accordion6">
        <label for="accordion6" class="accordion-item-title"><span class="icon"></span>What information do I need to provide as a patient requesting blood on DonorZilla?</label>
        <div class="accordion-item-desc">Patients requesting blood on DonorZilla must provide details such as blood type, quantity required, location for donation, and any other specific requirements. Also, the patient can upload medical documents for the donors to see.</div>
    </div>
    <div class="accordion-item">
        <input type="checkbox" id="accordion7">
        <label for="accordion7" class="accordion-item-title"><span class="icon"></span>How does DonorZilla ensure the safety and reliability of blood donations?</label>
        <div class="accordion-item-desc">DonorZilla follows strict guidelines and procedures to verify donors' eligibility, including health screenings and blood tests. Additionally, users can rate and review donors, promoting accountability within the community.</div>
    </div>
    <div class="accordion-item">
        <input type="checkbox" id="accordion8">
        <label for="accordion8" class="accordion-item-title"><span class="icon"></span>Can I schedule blood donation appointments through DonorZilla?</label>
        <div class="accordion-item-desc">Yes, donors can schedule blood donation appointments directly through the app based on their availability and the needs of patients. these appointments can be set directly by the patient attendee and the donor using the in app chat in donorZilla</div>
    </div>
    <div class="accordion-item">
        <input type="checkbox" id="accordion9">
        <label for="accordion9" class="accordion-item-title"><span class="icon"></span>Is my personal information secure on DonorZilla?</label>
        <div class="accordion-item-desc">DonorZilla takes user privacy and data security seriously, employing encryption and other measures to safeguard personal information.</div>
    </div>
    <div class="accordion-item">
        <input type="checkbox" id="accordion10">
        <label for="accordion10" class="accordion-item-title"><span class="icon"></span>What happens after I donate blood through DonorZilla?</label>
        <div class="accordion-item-desc">After donating blood, donors receive confirmation and appreciation through the app. The donated blood is then allocated to patients in need based on compatibility and urgency.</div>
    </div>
    <div class="accordion-item">
        <input type="checkbox" id="accordion11">
        <label for="accordion11" class="accordion-item-title"><span class="icon"></span>How can I spread awareness about DonorZilla in my community?</label>
        <div class="accordion-item-desc">You can spread awareness about DonorZilla by sharing the app with friends, and family, and on social media platforms. Additionally, organizing blood donation drives or partnering with local organizations can help promote the app's usage.</div>
    </div>
    <div class="accordion-item">
        <input type="checkbox" id="accordion12">
        <label for="accordion12" class="accordion-item-title"><span class="icon"></span>Is DonorZilla available in multiple languages?</label>
        <div class="accordion-item-desc">At the moment, DonorZilla is available in English, but efforts are underway to expand language support to reach a broader audience.</div>
    </div>
</div>
<div id="blog"></div>
<div id="download"></div>
<section class="change red_bg">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-xl-9 col-lg-9  col-12">
                <div class="change_content">
                    <h2>Download The App Now</h2>
                    <p>Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but occasionally circumstances occur in which toil and pain can procure reat pleasure.</p>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3  col-12 text-xl-end text-lg-end text-center">
                <div class="app-donate1">
                    <!-- <img src="assets/home_assets/images/ios.png"> -->
                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.insight.donorzilla">
                        <img src="assets/home_assets/images/android.png">
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<footer>
    <div class="footer_top pt-115">
        <div class="container ">
            <div class="row footer_middle">
                <div class="col-xl-6 col-lg-6 col-12 ">
                    <div class="footer_subscrive">
                        <img src="assets/home_assets/images/logo-white.png">
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-12">
                    <div class="footer_social">
                        <ul class="page_link d-flex justify-content-xxl-end justify-content-xl-end justify-content-lg-end justify-content-center gap-2 flex-wrap">
                            <li><a href="privacy_policy">Privacy Policy</a></li>
                            <li><a href="#">/</a></li>
                            <li><a href="terms_condition">Terms & Condition</a></li>
                            <li><a href="#">/</a></li>
                            <li><a href="delete_account">Delete Account</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer_bottom">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center">
                    <p>Copyright &copy; 2024. All rights reserved</p>
                </div>
            </div>
        </div>
    </div>
</footer>
<div class="offcanvas offcanvas-start" id="offcanvas-mobile">
    <div class="offcanvas-body">
        <div class="mobile-menu">
            <a href="" class="logo py-3"><img src="assets/home_assets/images/logo.png" alt="logo" class="img-fluid"></a>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas"></button>
            <ul class="accordion accordion-flush mobile_dropdown" id="accordionFlushExample">
                <li class="accordion-item">
                    <h2><a href="#"> Why Donorzilla?</a></h2>
                </li>
                <li class="accordion-item">
                    <h2><a href="#">Faq</a></h2>
                </li>
                <li class="accordion-item">
                    <h2><a href="#">Blog</a></h2>
                </li>
                <li class="accordion-item">
                    <h2><a href="#">Download App</a></h2>
                </li>
                <li class="accordion-item">
                    <h2><a href="#">Contact Us</a></h2>
                </li>
            </ul>
        </div>
    </div>
</div>