<div class="progress-wrap cursor-pointer active-progress">
    <svg class="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" style="transition: stroke-dashoffset 10ms linear 0s; stroke-dasharray: 307.919, 307.919; stroke-dashoffset: 221.377;">
        </path>
    </svg>
</div>
<header>
    <div class="header_top d-none d-lg-block d-xl-block d-xxl-block">
        <div class="container">
            <div class="row">
                <div class="col-xl-3 col-lg-3">
                    <div class="header_top_content">
                        <span><i class="fa-solid fa-envelope"></i></span>
                        <a href="mailto:company@domin.com">contact@donorzilla.com</a>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3"></div>
                <div class="col-xl-2 col-lg-2"></div>
                <div class="col-xl-4 col-lg-4">
                    <div class="header_top_social">
                        <p>Follow Now</p>
                        <ul class="d-flex">
                            <li><a href="https://www.facebook.com/donorzilla/" target="_blank"><i class="fa-brands fa-facebook-f"></i></a></li>
                            <li><a href="https://twitter.com/DonorZilla" target="_blank"><i class="fa-brands fa-twitter"></i></a></li>
                            <li><a href=" https://www.instagram.com/donorzilla/" target="_blank"><i class="fa-brands fa-instagram"></i></a></li>
                            <li><a href="https://www.pinterest.com/donorzilla/" target="_blank"><i class="fa-brands fa-pinterest-p"></i></a></li>
                            <li><a href="https://www.youtube.com/@donorzilla" target="_blank"><i class="fa-brands fa-youtube"></i></a></li>
                            <li><a href="https://www.linkedin.com/company/donorzilla/" target="_blank"><i class="fa-brands fa-linkedin"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="header_bottom">
        <div class="container">
            <div class="row align-items-center position-relative">
                <div class="col-xl-2 col-lg-2 col-md-4 col-6">
                    <div class="header_logo">
                        <a href=""><img src="assets/home_assets/images/logo.png" alt="images not founds" class="img-fluid"></a>
                    </div>
                </div>
                <div class="col-xl-10 col-lg-10 d-none d-xxl-block d-xl-block">
                    <ul class="main_menu">
                        <li class="position-relative">
                            <a href="#why">Why Donorzilla?</a>
                        </li>
                        <li><a href="#faq">Faq</a></li>
                        <li><a href="#blog">Blog</a></li>
                        <li><a href="#download">Download App</a></li>
                        <li><a href="#contact">Contact Us</a></li>
                    </ul>
                </div>
                <div class="col-lg-10 col-md-8 col-6 d-block d-xxl-none d-xl-none">
                    <div class="d-flex align-items-center gap-2 justify-content-end">
                        <div class="mobile_menu">
                            <button class="header_toggle_btn bg-transparent border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas-mobile">
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<section id="profile-info">
    <div class="container">
        <div class="profile-details light-bg border-top pt-120 lg-pt-80 pb-150 lg-pb-80">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3">
                        <div class="member-img" >
                            <img src="assets/home_assets/images/profile.jpg">
                        </div>
                    </div>
                    <div class="col-lg-9 ">
                        <div class="space11 ps-xl-4 ps-lg-0 pe-xl-4 pe-lg-3 pe-3 border-right h-100">
                            <h2 class="name fw-bold">Dr. Randhir Sud.</h2>
                            <div class="post">Cardiologist</div>    
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                        </div>
                        <div class="profile-review-rating prof-rvw">
                            <fieldset>
                                <input type="radio" name="rating" id="rating-1" value="1">
                                <label for="rating-1">1 Star</label>
                                <input type="radio" name="rating" id="rating-2" value="2">
                                <label for="rating-2">2 Stars</label>
                                <input type="radio" name="rating" id="rating-3" value="3">
                                <label for="rating-3">3 Stars</label>
                                <input type="radio" name="rating" id="rating-4" value="4">
                                <label for="rating-4">4 Stars</label>
                                <input type="radio" name="rating" id="rating-5" value="5">
                                <label for="rating-5">5 Stars</label>
                            </fieldset>
                        </div>
                    </div>
                </div>
            </div>
            <div class="about-profile-info">
                <div class="container">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="text-profile-info">
                                <h3>Overview</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                                cillum dolore eu fugiat nulla pariatur. </p>
                                <div class="tabs">
                                    <ul class="tabs-nav">
                                        <li><a href="#tab-1">Details</a></li>
                                        <li><a href="#tab-2">Gallery</a></li>
                                        <li><a href="#tab-3">Reviews</a></li>
                                    </ul>
                                    <div class="tabs-stage">
                                        <div class="tabcontent" id="tab-1">
                                            <table class="w-100 space-m lg-mt-20">
                                                <tbody>
                                                    <tr>
                                                        <td>Location:</td>
                                                        <td>Delhi, India</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Position:</td>
                                                        <td>Founder &amp; CEO</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Email:</td>
                                                        <td>mathfir@support.com</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Age:</td>
                                                        <td>38</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Qualification:</td>
                                                        <td>Master Degree</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Gender:</td>
                                                        <td>Male</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="tabcontent" id="tab-2">
                                            <div class="teb-gallery">
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <img src="assets/home_assets/images/n1.jpg">
                                                    </div>
                                                    <div class="col-md-4">
                                                        <img src="assets/home_assets/images/n2.jpg">
                                                    </div>
                                                    <div class="col-md-4">
                                                        <img src="assets/home_assets/images/n3.jpg">
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <img src="assets/home_assets/images/n1.jpg">
                                                    </div>
                                                    <div class="col-md-4">
                                                        <img src="assets/home_assets/images/n2.jpg">
                                                    </div>
                                                    <div class="col-md-4">
                                                        <img src="assets/home_assets/images/n3.jpg">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tabcontent" id="tab-3">
                                            <div class="ClientReview_Card">
                                                <div class="ClientReview_Top">
                                                    <div class="ClientReview_Pfp">
                                                        <img src="https://lh3.googleusercontent.com/a-/ALV-UjVJ_jDRiVlgv_ui3fOqcc5JU7fY3lncqpzQSvZyGURM00oqOMi28w=w90-h90-p-rp-mo-ba5-br100"/>
                                                    </div>
                                                    <div class="ClientReview_Stars">★★★★★</div>
                                                </div>
                                                <div class="ClientReview_Body">
                                                    "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                                    quis nostrud exercitation."
                                                </div>
                                                <div class="ClientReview_Name">— Chonya Alvarez</div>
                                            </div>
                                            <div class="ClientReview_Card">
                                                <div class="ClientReview_Top">
                                                    <div class="ClientReview_Pfp">
                                                        <img src="https://lh3.googleusercontent.com/a-/ALV-UjVJ_jDRiVlgv_ui3fOqcc5JU7fY3lncqpzQSvZyGURM00oqOMi28w=w90-h90-p-rp-mo-ba5-br100"/>
                                                    </div>
                                                    <div class="ClientReview_Stars">
                                                        ★★★★★
                                                    </div>
                                                </div>
                                                <div class="ClientReview_Body">
                                                    "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                                    quis nostrud exercitation."
                                                </div>
                                                <div class="ClientReview_Name">
                                                    — 
                                                    Chonya Alvarez
                                                </div>                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="space pt-45 pb-45 ps-xl-4 ps-lg-0 ps-3 pe-xl-4 pe-lg-0 pe-3">
                                <h3 class="detail-profile">Details</h3>
                                <table class="w-100 space-m lg-mt-20">
                                    <tbody>
                                        <tr>
                                            <td>Location:</td>
                                            <td>Delhi, India</td>
                                        </tr>
                                        <tr>
                                            <td>Position:</td>
                                            <td>Founder &amp; CEO</td>
                                        </tr>
                                        <tr>
                                            <td>Email:</td>
                                            <td>mathfir@support.com</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div><br>
                            <div class="right-contact-text">
                                <h3>Contact</h3>
                                <p><a href="#"><i class="fa fa-phone-square" aria-hidden="true"></i> 7894561230</a></p>
                                <hr>
                                <h3>Address</h3>
                                <p> Delhi Industrial Area is located in district West Delhi, Delhi</p><br>
                                <h3>Get the List </h3>
                                <form class="get-list">
                                    <input type="text" id="name" name="user_name" placeholder="Name*">
                                    <input type="text" id="name" name="user_name" placeholder="Phone Number*">
                                </form>
                                <div class="get-list-btn">
                                    <a href="#">Send Enquiry</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<footer>
    <div class="footer_top pt-115">
        <div class="container ">
            <div class="row footer_middle">
                <div class="col-xl-6 col-lg-6 col-12 ">
                    <div class="footer_subscrive">
                        <img src="assets/home_assets/images/logo-white.png">
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-12">
                    <div class="footer_social">
                        <ul class="page_link d-flex justify-content-xxl-end justify-content-xl-end justify-content-lg-end justify-content-center gap-2 flex-wrap">
                            <li><a href="privacy_policy">Privacy Policy</a></li>
                            <li><a href="#">/</a></li>
                            <li><a href="terms_condition">Terms & Condition</a></li>
                            <li><a href="#">/</a></li>
                            <li><a href="delete_account">Delete Account</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer_bottom">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center">
                    <p>Copyright &copy; 2024. All rights reserved</p>
                </div>
            </div>
        </div>
    </div>
</footer>
<div class="offcanvas offcanvas-start" id="offcanvas-mobile">
    <div class="offcanvas-body">
        <div class="mobile-menu">
            <a href="" class="logo py-3"><img src="assets/home_assets/images/logo.png" alt="logo" class="img-fluid"></a>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas"></button>
            <ul class="accordion accordion-flush mobile_dropdown" id="accordionFlushExample">
                <li class="accordion-item">
                    <h2><a href="#"> Why Donorzilla?</a></h2>
                </li>
                <li class="accordion-item">
                    <h2><a href="#">Faq</a></h2>
                </li>
                <li class="accordion-item">
                    <h2><a href="#">Blog</a></h2>
                </li>
                <li class="accordion-item">
                    <h2><a href="#">Download App</a></h2>
                </li>
                <li class="accordion-item">
                    <h2><a href="#">Contact Us</a></h2>
                </li>
            </ul>
        </div>
    </div>
</div>