import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { BusinessMaster } from '../models/businessMaster';
import { Notification } from '../models/notification';

import { BusinessMasterService } from "../services/businessMaster.service";
import { NotificationService } from "../services/notification.service";

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: [
		'./dashboard.component.css',
		'../../assets/css/material-dashboard.css',
		'../../assets/css/responsive.css',
	]
})
export class DashboardComponent implements OnInit {

	addBusinessForm = new FormGroup({
		email: new FormControl("", [Validators.required]),
		e_phone: new FormControl("", [Validators.required]),
		address: new FormControl("", [Validators.required]),
  	});

	addContactForm = new FormGroup({
		c_name: new FormControl("", [Validators.required]),
		c_email: new FormControl("", [Validators.required]),
		c_phone: new FormControl("", [Validators.required]),
  	});

	addBioForm = new FormGroup({
		about: new FormControl("", [Validators.required])
  	});

  	get f() {
		return this.addBusinessForm.controls;
	}

	get g() {
		return this.addContactForm.controls;
	}

	get h() {
		return this.addBioForm.controls;
	}

	dynamicMapUrl: any;

	b_id: any;
	user_id = 0;
	role_type = 0;
	notificationCounter = 0;
	type: any;
	bio: any;

	detailedList: any;
	businessList: any;
	notificationList: any;
	
	errorMessage = "";
	successMessage = "";
	closeResult = "";
	formErrorMessages = {
		emailError : "",
		e_phoneError : "",
		addressError : "",
		c_nameError : "",
		c_emailError : "",
		c_phoneError : "",
		aboutError : "",
	};

	constructor(
		private sanitizer: DomSanitizer,
		private businessMasterService: BusinessMasterService,
		private notificationService: NotificationService,
		private modalService: NgbModal,
		private route: ActivatedRoute,
		private router: Router
	) {}

	ngOnInit() {
		var currentUser = localStorage.getItem('currentUser');
		if (currentUser) {
			var userArray = JSON.parse(currentUser);
			this.user_id = userArray.data.id;
			this.role_type = userArray.data.role_type;
			if(!this.role_type){
				setTimeout(() => {
					this.router.navigate(['profile']);
				}, 100);
				return;
			}
			this.getAllBusiness(this.user_id);
			this.getNotification(this.user_id);
			this.getNotificationCounter(this.user_id);
			this.b_id = this.route.snapshot.queryParams.b_id;
			if(this.b_id){
				this.getRoleDetailsById(this.b_id);
			} else{
				this.getRoleDetails(this.user_id);
			}
		}
	}

	transform() {
		return this.sanitizer.bypassSecurityTrustResourceUrl(
		 	this.dynamicMapUrl
		);
	}

	onChange(business_id: any): void {
		window.location.href = 'dashboard?b_id='+business_id.target.value;
	}

	getNotification(user_id: any): void {
		this.notificationService.getNotificationByUserId({ user_id } as Notification)
		.subscribe(
			data => {
				this.notificationList = data.data;
			}
		);
	}

	getNotificationCounter(user_id: any): void {
		this.notificationService.getNotificationCounterByUserId({ user_id } as Notification)
		.subscribe(
			data => {
				this.notificationCounter = data.data.total;
			}
		);
	}

	updateNotification(): void {
		let user_id = this.user_id;
		this.notificationService.updateNotificationByUserId({ user_id } as Notification)
		.subscribe(
			data => {
				if (data.status == 200) {
					this.successMessage = data.message;
					setTimeout(() => {
						window.location.reload();
					}, 2000);
				} else {
					this.errorMessage = data.message;
				}
			}
		);
	}

	getRoleDetailsById(b_id: any): void {
		this.businessMasterService.getBusinessById({ b_id } as BusinessMaster)
			.subscribe(
				data => {
					this.detailedList = data.data;
					this.type = data.data.type;
					this.dynamicMapUrl = 'https://maps.google.com/maps?q='+data.data.latitude+', '+data.data.longitude+'&z=15&output=embed';
				}
			);
	}

	getRoleDetails(user_id: any): void {
		this.businessMasterService.getBusniessMasterByUserId({ user_id } as BusinessMaster)
		.subscribe(
			data => {
				this.detailedList = data.data;
				this.type = data.data.type;
				this.b_id = data.data.b_id;
				this.dynamicMapUrl = 'https://maps.google.com/maps?q='+data.data.latitude+', '+data.data.longitude+'&z=15&output=embed';
			}
		);
	}

	getAllBusiness(user_id: any): void {
		this.businessMasterService.getAllBusinessByUserId({ user_id } as BusinessMaster)
		.subscribe(
			data => {
				this.businessList = data.data;
			}
		);
	}

	openBioAdd(content: any): void {
		let b_id = this.b_id;

		this.businessMasterService.getInfoByBusinessId({
			b_id,
		} as BusinessMaster).subscribe(
		(data) => {
			if (data.status == 200) {
				this.addBioForm.controls["about"].setValue(data.data.about);
			}
		},
		(error) => {
		});

		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", size: 'md', backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}
	
	addBio($event: any): void {

		($event.submitter as HTMLButtonElement).disabled = true;
		let about = this.addBioForm.value.about.trim();

		this.businessMasterService.updateBio({
			about
		} as BusinessMaster)
		.subscribe(
			(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
				setTimeout(() => {
				document.getElementById("addBannerModalClose")?.click();
					this.addBioForm.reset();
				}, 2000);
			} else {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = data.message;
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;

			}
			},
			(error) => {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = error;
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}

	openBusinessAdd(content: any): void {
		let b_id = this.b_id;

		this.businessMasterService.getInfoByBusinessId({
			b_id,
			} as BusinessMaster).subscribe(
			(data) => {
				if (data.status == 200) {
					this.addBusinessForm.controls["email"].setValue(data.data.email);
					this.addBusinessForm.controls["e_phone"].setValue(data.data.e_phone);
					this.addBusinessForm.controls["address"].setValue(data.data.address);
				}
			},
			(error) => {
			}
		);

		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	addBusiness($event: any): void {

		($event.submitter as HTMLButtonElement).disabled = true;
		let email = this.addBioForm.value.email.trim();
		let e_phone = this.addBioForm.value.e_phone.trim();
		let address = this.addBioForm.value.address.trim();

		this.businessMasterService.updateBusinessInfo({
			email,
			e_phone,
			address
		} as BusinessMaster)
		.subscribe(
			(data) => {
			this.errorMessage = "";
			if (data.status == 200) {
				setTimeout(() => {
				document.getElementById("addBannerModalClose")?.click();
					this.addBioForm.reset();
				}, 2000);
			} else {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = data.message;
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;

			}
			},
			(error) => {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = error;
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}

	openContactAdd(content: any): void {
		let b_id = this.b_id;

		this.businessMasterService.getInfoByBusinessId({
			b_id,
			} as BusinessMaster).subscribe(
			(data) => {
				if (data.status == 200) {
					this.addContactForm.controls["c_name"].setValue(data.data.c_name);
					this.addContactForm.controls["c_email"].setValue(data.data.c_email);
					this.addContactForm.controls["c_phone"].setValue(data.data.c_phone);
				}
			},
			(error) => {
			}
		);

		this.modalService
		.open(content, { ariaLabelledBy: "modal-basic-title", size: 'lg', backdrop : 'static', keyboard : false })
		.result.then(
			(result) => {
			this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			}
		);
	}

	addContact($event: any): void {

		($event.submitter as HTMLButtonElement).disabled = true;
		let c_name = this.addBioForm.value.c_name.trim();
		let c_phone = this.addBioForm.value.c_phone.trim();
		let c_email = this.addBioForm.value.c_email.trim();

		this.businessMasterService.updateBusinessInfo({
			c_name,
			c_phone,
			c_email
		} as BusinessMaster)
		.subscribe(
			(data) => {
				this.errorMessage = "";
				if (data.status == 200) {
					setTimeout(() => {
					document.getElementById("addBannerModalClose")?.click();
						this.addBioForm.reset();
					}, 2000);
				} else {
					const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
					let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
					dynamicMessage.value = data.message;
					errorMessage?.click();
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			},
			(error) => {
				const errorMessage = document.getElementById('btnError') as HTMLInputElement | null;
				let dynamicMessage : any = document.getElementById("dynamic_message") as HTMLInputElement | null;
				dynamicMessage.value = error;
				errorMessage?.click();
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}
	
	makeYourself($event: any): void{
		let make_public = $event.currentTarget.checked;

		this.businessMasterService
		.updateMakePublic({
			make_public
		} as BusinessMaster)
		.subscribe(
			(data) => {
				this.errorMessage = "";
				if (data.status == 200) {
					this.successMessage = data.message;
					setTimeout(() => {
						document.getElementById("addBannerModalClose")?.click();
						window.location.reload();
					}, 2000);
				} else {
					this.errorMessage = data.message;
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			},
			(error) => {
				this.errorMessage = error;
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}
	
	newsletter($event:any): void{
		let newsletter = $event.currentTarget.checked;

		this.businessMasterService
		.updateNewsletter({
			newsletter
		} as BusinessMaster)
		.subscribe(
			(data) => {
				this.errorMessage = "";
				if (data.status == 200) {
					this.successMessage = data.message;
					setTimeout(() => {
						document.getElementById("addBannerModalClose")?.click();
						window.location.reload();
					}, 2000);
				} else {
					this.errorMessage = data.message;
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			},
			(error) => {
				this.errorMessage = error;
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}

	latestUpdate ($event:any): void{
		let latest_update = $event.currentTarget.checked;

		this.businessMasterService
		.updateLatestUpdate({
			latest_update
		} as BusinessMaster)
		.subscribe(
			(data) => {
				this.errorMessage = "";
				if (data.status == 200) {
					this.successMessage = data.message;
					setTimeout(() => {
						document.getElementById("addBannerModalClose")?.click();
						window.location.reload();
					}, 2000);
				} else {
					this.errorMessage = data.message;
					($event.submitter as HTMLButtonElement).disabled = false;
				}
			},
			(error) => {
				this.errorMessage = error;
				($event.submitter as HTMLButtonElement).disabled = false;
			}
		);
	}
	
	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
		return "by pressing ESC";
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
		return "by clicking on a backdrop";
		} else {
		return `with: ${reason}`;
		}
	}
}
