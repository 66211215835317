import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-privacy-policy',
	templateUrl: './privacy-policy.component.html',
	styleUrls: [
		'./privacy-policy.component.css',
		'../../assets/home_assets/css/bootstrap.min.css',
		'../../assets/home_assets/css/animate.css',
		'../../assets/home_assets/css/all.css',
		'../../assets/home_assets/css/slick.css',
		'../../assets/home_assets/css/fancybox.css',
		'../../assets/home_assets/css/style.css'
	]
})
export class PrivacyPolicyComponent implements OnInit {

	constructor(
	) { }

	ngOnInit(): void {
	}

}