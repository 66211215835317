<div class="progress-wrap cursor-pointer active-progress">
    <svg class="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" style="transition: stroke-dashoffset 10ms linear 0s; stroke-dasharray: 307.919, 307.919; stroke-dashoffset: 221.377;">
        </path>
    </svg>
</div>
<header>
    <div class="header_top d-none d-lg-block d-xl-block d-xxl-block">
        <div class="container">
            <div class="row">
                <div class="col-xl-3 col-lg-3">
                    <div class="header_top_content">
                        <span><i class="fa-solid fa-envelope"></i></span>
                        <a href="mailto:company@domin.com">contact@donorzilla.com</a>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3"></div>
                <div class="col-xl-2 col-lg-2"></div>
                <div class="col-xl-4 col-lg-4">
                    <div class="header_top_social">
                        <p>Follow Now</p>
                        <ul class="d-flex">
                            <li><a href="https://www.facebook.com/donorzilla/" target="_blank"><i class="fa-brands fa-facebook-f"></i></a></li>
                            <li><a href="https://twitter.com/DonorZilla" target="_blank"><i class="fa-brands fa-twitter"></i></a></li>
                            <li><a href=" https://www.instagram.com/donorzilla/" target="_blank"><i class="fa-brands fa-instagram"></i></a></li>
                            <li><a href="https://www.pinterest.com/donorzilla/" target="_blank"><i class="fa-brands fa-pinterest-p"></i></a></li>
                            <li><a href="https://www.youtube.com/@donorzilla" target="_blank"><i class="fa-brands fa-youtube"></i></a></li>
                            <li><a href="https://www.linkedin.com/company/donorzilla/" target="_blank"><i class="fa-brands fa-linkedin"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="header_bottom">
        <div class="container">
            <div class="row align-items-center position-relative">
                <div class="col-xl-2 col-lg-2 col-md-4 col-6">
                    <div class="header_logo">
                        <a href=""><img src="assets/home_assets/images/logo.png" alt="images not founds" class="img-fluid"></a>
                    </div>
                </div>
                <div class="col-xl-10 col-lg-10 d-none d-xxl-block d-xl-block">
                    <ul class="main_menu">
                        <li class="position-relative">
                            <a href="#why">Why Donorzilla?</a>
                        </li>
                        <li><a href="#faq">Faq</a></li>
                        <li><a href="#blog">Blog</a></li>
                        <li><a href="#download">Download App</a></li>
                        <li><a href="#contact">Contact Us</a></li>
                    </ul>
                </div>
                <div class="col-lg-10 col-md-8 col-6 d-block d-xxl-none d-xl-none">
                    <div class="d-flex align-items-center gap-2 justify-content-end">
                        <div class="dropdown dropdown_search">
                            <button class="search-btn " data-bs-toggle="dropdown" aria-expanded="true"><i class="fa-solid fa-magnifying-glass"></i></button>
                            <div class="dropdown-menu dropdown-menu-end" data-popper-placement="bottom-end">
                                <form class="search-form d-flex align-items-center gap-2">
                                    <input type="text" placeholder="Search..." class="theme-input bg-transparent">
                                    <button type="submit" class="submit-btn">Go</button>
                                </form>
                            </div>
                        </div>
                        <div class="mobile_menu">
                            <button class="header_toggle_btn bg-transparent border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas-mobile">
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<iframe src="https://admin.donorzilla.com/delete_account" style="width: 100%; height: 100%;"></iframe>

<footer>
    <div class="footer_top pt-115">
        <div class="container ">
            <div class="row footer_middle">
                <div class="col-xl-6 col-lg-6 col-12 ">
                    <div class="footer_subscrive">
                        <img src="assets/home_assets/images/logo-white.png">
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-12">
                    <div class="footer_social">
                        <ul class="page_link d-flex justify-content-xxl-end justify-content-xl-end justify-content-lg-end justify-content-center gap-2 flex-wrap">
                            <li><a href="privacy_policy">Privacy Policy</a></li>
                            <li><a href="#">/</a></li>
                            <li><a href="terms_condition">Terms & Condition</a></li>
                            <li><a href="#">/</a></li>
                            <li><a href="delete_account">Delete Account</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer_bottom">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center">
                    <p>Copyright &copy; 2024. All rights reserved</p>
                </div>
            </div>
        </div>
    </div>
</footer>
<div class="offcanvas offcanvas-start" id="offcanvas-mobile">
    <div class="offcanvas-body">
        <div class="mobile-menu">
            <a href="" class="logo py-3"><img src="assets/home_assets/images/logo.png" alt="logo" class="img-fluid"></a>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas"></button>
            <ul class="accordion accordion-flush mobile_dropdown" id="accordionFlushExample">
                <li class="accordion-item">
                    <h2><a href="#"> Why Donorzilla?</a></h2>
                </li>
                <li class="accordion-item">
                    <h2><a href="#">Faq</a></h2>
                </li>
                <li class="accordion-item">
                    <h2><a href="#">Blog</a></h2>
                </li>
                <li class="accordion-item">
                    <h2><a href="#">Download App</a></h2>
                </li>
                <li class="accordion-item">
                    <h2><a href="#">Contact Us</a></h2>
                </li>
            </ul>
        </div>
    </div>
</div>