<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"/>

<body class="bg-white">
  	<div class="logo-profile">
		<img src="assets/img/logo.png">
	</div>
  	<section id="profile-creation">
		<div class="container">
			<div class="tab-profile">
				<div class="tab-status">
					<span class="tab active">1</span>
					<span class="tab">2</span>
					<span class="tab">3</span>
					<span class="tab">4</span>
					<span class="tab">5</span>
				</div>
				<input type="hidden" id="longitude"/>
				<input type="hidden" id="latitude"/>
				<input type="hidden" id="address" />
  				<form [formGroup]="profileForm" (ngSubmit)="profile($event)">
					<div role="tab-list">
						<div role="tabpanel" *ngIf="step1" class="tabpanel">
							<h3>1. Who You Are?</h3>
							<div class="wrapper">
								<select class="custom-select" (change)="onChangeSelect($event)" formControlName="role_type" data-control="select2" data-hide-search="true" [ngClass]="{'inputError': f.role_type.touched && f.role_type.errors && f.role_type.errors.required }"
								[ngClass]="{'inputError': formErrorMessages.role_typeError }">
									<option  value="" selected>Select</option>
									<option class="select" *ngFor="let role of roleList; let i = index" [value]="role.id">
										{{role.name}}
									</option>
								</select>
							</div>
						</div>
						<div role="tabpanel" class="tabpanel" *ngIf="step2">
							<h3>2. Name of {{title}}?</h3>
							<input type="text" minlength="3" maxlength="50" formControlName="name" data-control="select2" data-hide-search="true" [ngClass]="{'inputError': f.name.touched && f.name.errors && f.name.errors.required }"
							[ngClass]="{'inputError': formErrorMessages.nameError }" class="form-input" placeholder="Type Your {{title}} Name..">
							<p>{{formErrorMessages.nameError}}</p>
						</div>
						<div role="tabpanel" class="tabpanel" *ngIf="step3">
							<h3>3. Your Address?</h3>
							<input type="text" minlength="5" maxlength="100" formControlName="myaddress" placeholder="Enter your address" data-control="select2" data-hide-search="true" class="form-input" id="myAddress" style="width: 100%;"/>
							<div id="map"></div>
						</div>
						<div role="tabpanel" class="tabpanel" *ngIf="step4">
							<h3>4. Your Email Address?</h3>
							<input type="email" minlength="5" maxlength="50" formControlName="email" data-control="select2" data-hide-search="true" [ngClass]="{'inputError': f.email.touched && f.email.errors && f.email.errors.required }"
							[ngClass]="{'inputError': formErrorMessages.emailError }" class="form-input" placeholder="hello@example.com">
							<p>{{formErrorMessages.emailError}}</p>
						</div>
 						<div role="tabpanel" class="tabpanel" *ngIf="step5">
							<h3>5. Your Phone Number?</h3>
							<input type="text" minlength="10" maxlength="10" onkeyup="this.value=this.value.replace(/[^0-9]/g,'');" formControlName="phone" data-control="select2" data-hide-search="true" [ngClass]="{'inputError': f.phone.touched && f.phone.errors && f.phone.errors.required }"
							[ngClass]="{'inputError': formErrorMessages.phoneError }"class="form-input" placeholder="Enter Your Phone No.">
							<br><br>
							<h3>Your Alternate Phone Number?</h3>
							<input type="text" minlength="10" maxlength="10" onkeyup="this.value=this.value.replace(/[^0-9]/g,'');" formControlName="e_phone" data-control="select2" data-hide-search="true" [ngClass]="{'inputError': f.e_phone.touched && f.e_phone.errors && f.e_phone.errors.required }"
							[ngClass]="{'inputError': formErrorMessages.e_phoneError }"class="form-input" placeholder="Enter Your Phone No.">
						</div>
						<div role="tabpanel" class="tabpanel" *ngIf="step6">
							<h3>6. Your Contact Person Name?</h3>
							<input type="string" minlength="3" maxlength="30" formControlName="c_name" data-control="select2" data-hide-search="true" [ngClass]="{'inputError': f.c_name.touched && f.c_name.errors && f.c_name.errors.required }"
							[ngClass]="{'inputError': formErrorMessages.c_nameError }" class="form-input" placeholder="Enter Contact Person Name"><br><br>
							<h3>Your Contact Person Email?</h3>
							<input type="text" minlength="5" maxlength="30" formControlName="c_email" data-control="select2" data-hide-search="true" [ngClass]="{'inputError': f.c_email.touched && f.c_email.errors && f.c_email.errors.required }"
							[ngClass]="{'inputError': formErrorMessages.c_emailError }" class="form-input" placeholder="Enter Contact Person Email"><br><br>
							<h3>Your Contact Person Phone?</h3>
							<input type="text" minlength="10" maxlength="10" onkeyup="this.value=this.value.replace(/[^0-9]/g,'');" formControlName="c_phone" data-control="select2" data-hide-search="true" [ngClass]="{'inputError': f.c_phone.touched && f.c_phone.errors && f.c_phone.errors.required }"
							[ngClass]="{'inputError': formErrorMessages.c_phoneError }" class="form-input" placeholder="Enter Contact Person Phone">
						</div>
						<div role="tabpanel" class="tabpanel" *ngIf="step7">
							<h3>7. Let us know more about yourself?</h3>
							<textarea formControlName="about" data-control="select2" data-hide-search="true" [ngClass]="{'inputError': f.about.touched && f.about.errors && f.about.errors.required }"
							[ngClass]="{'inputError': formErrorMessages.aboutError }" class="form-input" maxlength="500" placeholder="Describe Your Company"></textarea>
					
						</div>
					</div>
					<div class="pagination">
						<a class="btn" *ngIf="previousBtn" (click)="handlePrevBtn($event)">	<i class="fa fa-arrow-left" aria-hidden="true"></i>  Previous</a>
						<button type="button" class="btn" id="nextbtnClick" *ngIf="nextBtn" (click)="handleFormValidation($event)">Next  <i class="fa fa-arrow-right" aria-hidden="true"></i></button>
						<button class="btn btn-submit" *ngIf="submitBtn">Submit</button>
					</div>
    			</form>
  			</div>
		</div>
	</section>
	<footer class="footer-bottom">
			<div>Donorzilla &copy;2024, All Rights Reserved.</div>
	</footer>
