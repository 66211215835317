<div class="progress-wrap cursor-pointer active-progress">
    <svg class="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" style="transition: stroke-dashoffset 10ms linear 0s; stroke-dasharray: 307.919, 307.919; stroke-dashoffset: 221.377;">
        </path>
    </svg>
</div>
<header>
    <div class="header_top d-none d-lg-block d-xl-block d-xxl-block">
        <div class="container">
            <div class="row">
                <div class="col-xl-3 col-lg-3">
                    <div class="header_top_content">
                        <span><i class="fa-solid fa-envelope"></i></span>
                        <a href="mailto:company@domin.com">contact@donorzilla.com</a>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3"></div>
                <div class="col-xl-2 col-lg-2"></div>
                <div class="col-xl-4 col-lg-4">
                    <div class="header_top_social">
                        <p>Follow Now</p>
                        <ul class="d-flex">
                            <li><a href="https://www.facebook.com/donorzilla/" target="_blank"><i class="fa-brands fa-facebook-f"></i></a></li>
                            <li><a href="https://twitter.com/DonorZilla" target="_blank"><i class="fa-brands fa-twitter"></i></a></li>
                            <li><a href=" https://www.instagram.com/donorzilla/" target="_blank"><i class="fa-brands fa-instagram"></i></a></li>
                            <li><a href="https://www.pinterest.com/donorzilla/" target="_blank"><i class="fa-brands fa-pinterest-p"></i></a></li>
                            <li><a href="https://www.youtube.com/@donorzilla" target="_blank"><i class="fa-brands fa-youtube"></i></a></li>
                            <li><a href="https://www.linkedin.com/company/donorzilla/" target="_blank"><i class="fa-brands fa-linkedin"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="header_bottom">
        <div class="container">
            <div class="row align-items-center position-relative">
                <div class="col-xl-2 col-lg-2 col-md-4 col-6">
                    <div class="header_logo">
                        <a href=""><img src="assets/home_assets/images/logo.png" alt="images not founds" class="img-fluid"></a>
                    </div>
                </div>
                <div class="col-xl-10 col-lg-10 d-none d-xxl-block d-xl-block">
                    <ul class="main_menu">
                        <li class="position-relative">
                            <a href="#why">Why Donorzilla?</a>
                        </li>
                        <li><a href="#faq">Faq</a></li>
                        <li><a href="#blog">Blog</a></li>
                        <li><a href="#download">Download App</a></li>
                        <li><a href="#contact">Contact Us</a></li>
                    </ul>
                </div>
                <div class="col-lg-10 col-md-8 col-6 d-block d-xxl-none d-xl-none">
                    <div class="d-flex align-items-center gap-2 justify-content-end">
                        <div class="mobile_menu">
                            <button class="header_toggle_btn bg-transparent border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas-mobile">
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<section class="static-hero" id="div">
    <div class="hero-container">
        <div class="hero-inner">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-xl-7 col-lg-7 col-12" data-aos="fade-up" data-aos-duration="2500">
                        <div class="wpo-static-hero-inner">
                            <div class="slide-sub-title">
                                <h2>Maximize Your Healthcare Business Reach with <strong>DonorZilla!</strong></h2>
                            </div>
                            <div class="slide-text">
                                <p>Join India’s trusted healthcare platform and boost your visibility today.</p>
                            </div>
                            <div class="clearfix"></div>
                            <div class="slide-btn"><a class="theme-btn " href="#" style="background: #ea062b;">Sign Up Now</a> <a class="theme-btn" href="#how-began">Learn More</a></div>
                        </div>
                    </div>
                    <div class="col-xl-5 col-lg-5 col-12" >
                        <div class="bnr-right-immg">
                            <img src="assets/home_assets/business-images/b1.png" class="bnrr11" data-aos="fade-left" data-aos-duration="2500">
                            <img src="assets/home_assets/business-images/b2.png" class="bnrr12" data-aos="fade-up" data-aos-duration="2500">
                            <img src="assets/home_assets/business-images/b3.png" class="bnrr13" data-aos="fade-down" data-aos-duration="2500">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="line-shape-1">
        <img src="assets/home_assets/business-images/slider/line-1.png" alt="" />
    </div>
</section>
<section id="how-began">
    <div class="container">
        <div class="how-text">
            <h2 class="text-center">Why Choose DonorZilla for Your Business?</h2>
            <p class="text-center">DonorZilla connects healthcare providers with patients and service seekers, emphasizing the platform’s reach and credibility.</p>
        </div>
        <div class="row">
            <div class="col-md-4" data-aos="fade-right" data-aos-duration="2500">
                <div class="how-img">
                    <img src="assets/home_assets/business-images/1.jpg" />            
                </div>
            </div>
            <div class="col-md-8" data-aos="fade-left" data-aos-duration="2500">
                <div class="row">
                    <div class="col-md-4">
                        <div class="side-text">
                            <img src="assets/home_assets/business-images/icon/1.png" />
                            <h3>Increase Visibility</h3> 
                            <p>Showcase your services to a broad audience actively seeking healthcare solutions.</p>
                        </div>
                    </div>
                    <div class="col-md-4">
                          <div class="side-text">
                          <img src="assets/home_assets/business-images/icon/2.png" />
                            <h3>Build Trust</h3> 
                            <p>Verified listings ensure credibility and patient confidence.</p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="side-text">
                            <img src="assets/home_assets/business-images/icon/3.png" />
                            <h3>Boost Engagement</h3> 
                            <p>Receive inquiries, appointments, and reviews directly.</p>
                        </div>
                    </div>
                </div>
                <div class="row">  
                    <div class="col-md-4">
                        <div class="side-text">
                            <img src="assets/home_assets/business-images/icon/4.png" />
                            <h3>Real-Time Analytics</h3> 
                            <p>Track the performance of your listing with detailed insights.</p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="side-text">
                            <img src="assets/home_assets/business-images/icon/5.png" />
                            <h3>Easy Setup & Management</h3> 
                            <p>Effortless process to keep your listing up-to-date.</p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <a class="theme-btn thm-btnn first-btnn" href="#" style="background: #ea062b;">Start Your Free Listing</a>
                        <div class="click-btnn">
                            <img src="assets/home_assets/business-images/icon/click.png">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<br>
<section id="our-promise">
   
    <div class="container">
         <h2 class="text-center text-our">To Get Your Business Listed</h2>
    <p class="text-center">Explain how quick and easy it is to list a healthcare business on DonorZilla.</p>
        <div class="naccs">
            <section class="work-porcess-area section">
                <div class="process-info">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="single-process first text-center">
                                <i class="fa fa-sign-in" aria-hidden="true"></i>
                                <h4>Sign Up</h4>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="single-process secend text-center">
                                <i class="fa fa-table" aria-hidden="true"></i>
                                <h4>Enter Details</h4>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="single-process thard text-center">
                                <i class="fa fa-check-square-o" aria-hidden="true"></i>
                                <h4>Verify & Publish</h4>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="single-process last text-center">
                                <i class="fa fa-cogs" aria-hidden="true"></i>
                                <h4>Manage & Engage</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</section>

<div class="cta-btnn">
    <a class="theme-btn thm-btnn" href="#" style="background: #ea062b;">List My Business Now</a>
</div>

<br />
<br />

        <section id="industry-serv" data-aos="fade-up" data-aos-duration="2000">
            <div class="container">
                <div class="accordion">
                    <h2 class="text-center indus-head">FAQs</h2>
                    <div class="accordion-item">
                        <input type="checkbox" id="accordion1">
                        <label for="accordion1" class="accordion-item-title"><span class="icon"></span> What is DonorZilla Business Listing?</label>
                        <div class="accordion-item-desc">DonorZilla Business Listing allows hospitals, blood banks, pathology labs, and other healthcare providers to register their businesses and gain visibility among thousands of potential patients and donors. It works like a healthcare-focused business directory, helping you connect with the right audience.</div>
                    </div>
                    <div class="accordion-item">
                        <input type="checkbox" id="accordion2">
                        <label for="accordion2" class="accordion-item-title"><span class="icon"></span>How does DonorZilla help my business grow?</label>
                        <div class="accordion-item-desc">DonorZilla increases your business visibility by allowing patients and donors to find your services easily. By listing your business, you can attract more patients, manage appointments, and improve engagement with healthcare seekers in your area.</div>
                    </div>
                    <div class="accordion-item">
                        <input type="checkbox" id="accordion3">
                        <label for="accordion3" class="accordion-item-title"><span class="icon"></span>Who can register on DonorZilla?</label>
                        <div class="accordion-item-desc">Any healthcare provider, including hospitals, blood banks, pathology labs, diagnostic centers, and wellness centers, can register on DonorZilla. We cater to all healthcare service providers looking to increase their visibility and reach.</div>
                    </div>
                    <div class="accordion-item">
                        <input type="checkbox" id="accordion4">
                        <label for="accordion4" class="accordion-item-title"><span class="icon"></span>Is registering on DonorZilla free?</label>
                        <div class="accordion-item-desc">Yes, Business registration on DonorZilla is always a free process. However, we offer premium listing options with additional features to help your business stand out and attract more patients but these features will be available in the future.</div>
                    </div>
                    <div class="accordion-item">
                        <input type="checkbox" id="accordion5">
                        <label for="accordion5" class="accordion-item-title"><span class="icon"></span>How do I register my business on DonorZilla?</label>
                        <div class="accordion-item-desc">Simply click on the “Register Your Business” button, fill in the necessary details about your healthcare facility, and submit your information. Our team will review your submission, and you’ll be listed once approved.</div>
                    </div>
                    <div class="accordion-item">
                        <input type="checkbox" id="accordion6">
                        <label for="accordion6" class="accordion-item-title"><span class="icon"></span>Can I edit my business details after registering?</label>
                        <div class="accordion-item-desc">Yes, once your business is listed, you can easily edit or update your details through your DonorZilla dashboard. This ensures that your information stays up to date for patients and donors.</div>
                    </div>
                    <div class="accordion-item">
                        <input type="checkbox" id="accordion7">
                        <label for="accordion7" class="accordion-item-title"><span class="icon"></span>How will patients and donors find my business?</label>
                        <div class="accordion-item-desc">Patients and donors can search for healthcare services by location, type of facility, or specific needs on the DonorZilla Mobile App. Your business will appear in relevant search results, and users can contact you directly through the platform.</div>
                    </div>
                    <div class="accordion-item">
                        <input type="checkbox" id="accordion8">
                        <label for="accordion8" class="accordion-item-title"><span class="icon"></span>Can I track the performance of my listing?</label>
                        <div class="accordion-item-desc">Yes, DonorZilla provides analytics tools that allow you to track views, inquiries, and appointments generated through your listing. This helps you monitor the effectiveness of your listing and make improvements.</div>
                    </div>
                    <div class="accordion-item">
                        <input type="checkbox" id="accordion9">
                        <label for="accordion9" class="accordion-item-title"><span class="icon"></span>What if I need support with my business listing?</label>
                        <div class="accordion-item-desc">Our support team can help you with any issues or questions regarding your listing. You can contact us through the support section in your DonorZilla dashboard, and we’ll assist you promptly.</div>
                    </div>
                    <!-- <div class="accordion-item">
                        <input type="checkbox" id="accordion10">
                        <label for="accordion10" class="accordion-item-title"><span class="icon"></span>What happens after I donate blood through DonorZilla?</label>
                        <div class="accordion-item-desc">After donating blood, donors receive confirmation and appreciation through the app. The donated blood is then allocated to patients in need based on compatibility and urgency.</div>
                    </div>
                    <div class="accordion-item">
                        <input type="checkbox" id="accordion11">
                        <label for="accordion11" class="accordion-item-title"><span class="icon"></span>How can I spread awareness about DonorZilla in my community?</label>
                        <div class="accordion-item-desc">You can spread awareness about DonorZilla by sharing the app with friends, and family, and on social media platforms. Additionally, organizing blood donation drives or partnering with local organizations can help promote the app's usage.</div>
                    </div>
                    <div class="accordion-item">
                        <input type="checkbox" id="accordion12">
                        <label for="accordion12" class="accordion-item-title"><span class="icon"></span>Is DonorZilla available in multiple languages?</label>
                        <div class="accordion-item-desc">At the moment, DonorZilla is available in English, but efforts are underway to expand language support to reach a broader audience.</div>
                    </div> -->
                </div>
            </div>
        </section>

        <!------------------------------------------------------------------------>

        <!-- start wpo-service-section -->
        <section class="wpo-appoinment-section" data-aos="fade-up" data-aos-duration="2000">
            <div class="container">
                <div class="appoinment-wrap">
                    <div class="row align-items-center">
                        <div class="col-xl-6 col-lg-12 col-12">
                            <div class="appoinment-text">
                                <h2>Ready to Grow Your Healthcare Business?</h2>
                                <p>Encourage businesses to sign up now and take advantage of DonorZilla's powerful platform.</p>
                                <div class="grow-btn">
                                    <a class="theme-btn" href="#">Register My Business</a>
                                    <!-- <a class="theme-btn" href="#how-began">Learn More About the Benefits</a> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-12 col-12">
                            <div class="appoinment-right">
                                <img src="assets/home_assets/business-images/s2.jpg" />
                            </div>
                        </div>
                    </div>
                    <div class="shape-1">
                        <img src="assets/home_assets/business-images/appoinment-shape.png" alt="" />
                    </div>
                </div>
            </div>
        </section>
<footer>
    <div class="footer_top pt-115">
        <div class="container ">
            <div class="row footer_middle">
                <div class="col-xl-6 col-lg-6 col-12 ">
                    <div class="footer_subscrive">
                        <img src="assets/home_assets/images/logo-white.png">
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-12">
                    <div class="footer_social">
                        <ul class="page_link d-flex justify-content-xxl-end justify-content-xl-end justify-content-lg-end justify-content-center gap-2 flex-wrap">
                            <li><a href="privacy_policy">Privacy Policy</a></li>
                            <li><a href="#">/</a></li>
                            <li><a href="terms_condition">Terms & Condition</a></li>
                            <li><a href="#">/</a></li>
                            <li><a href="delete_account">Delete Account</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer_bottom">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center">
                    <p>Copyright &copy; 2024. All rights reserved</p>
                </div>
            </div>
        </div>
    </div>
</footer>
<div class="offcanvas offcanvas-start" id="offcanvas-mobile">
    <div class="offcanvas-body">
        <div class="mobile-menu">
            <a href="" class="logo py-3"><img src="assets/home_assets/images/logo.png" alt="logo" class="img-fluid"></a>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas"></button>
            <ul class="accordion accordion-flush mobile_dropdown" id="accordionFlushExample">
                <li class="accordion-item">
                    <h2><a href="#"> Why Donorzilla?</a></h2>
                </li>
                <li class="accordion-item">
                    <h2><a href="#">Faq</a></h2>
                </li>
                <li class="accordion-item">
                    <h2><a href="#">Blog</a></h2>
                </li>
                <li class="accordion-item">
                    <h2><a href="#">Download App</a></h2>
                </li>
                <li class="accordion-item">
                    <h2><a href="#">Contact Us</a></h2>
                </li>
            </ul>
        </div>
    </div>
</div>